import { DataFilterStrategyBase, DataFilterValueItem, EDataFilterType, Nullable } from '@/domain';

export enum EReportSocialPackageFilterItem {
  DatePeriod = 'datePeriod',
}

export type ReportSocialPackageFilterEditStrategy = DataFilterStrategyBase<EReportSocialPackageFilterItem>;

export type ReportSocialPackageFilterValues = {
  readonly [EReportSocialPackageFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
};

export const getReportSocialPackageFilterStrategies = (
  values: ReportSocialPackageFilterValues
): ReportSocialPackageFilterEditStrategy[] => {
  const strategies: ReportSocialPackageFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EReportSocialPackageFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EReportSocialPackageFilterItem.DatePeriod]?.value || null,
  });

  return strategies;
};
