import Api from '@/data/api';
import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import {
  DataFilterQueryDslOperator,
  ESocialPackageStatus,
  Nullable,
  Pageable,
  RzdSocialPackage,
  RzdSocialPackageCreateRequest,
  SportOption,
  UUID,
} from '@/domain';
import { PaginationSize } from '@/presentation/types';
import { SocialPackageTableFilterValues } from '@features/socialPackage/filterUtils';
import {
  getRestLimitsExist,
  getStatesFilterForSocialPackageTableTab,
  SocialPackageCounterByStatus,
  SocialPackageTableTabsCounter,
} from '@features/socialPackage/table/utils';
import { ESocialPackageTableTab } from '@features/socialPackage/types';
import Axios from 'axios';

export type AllCommandProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: ESocialPackageStatus[];
  };
  readonly filters: SocialPackageTableFilterValues;
  /** Фильтр на то что лимиты по отдыху соц. пакета распределены */
  readonly restLimitsExist?: boolean;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type ArchiveCommandProps = {
  readonly ids: UUID[];
  readonly reason: SportOption;
  readonly comment?: string;
};

type ActivateCommandProps = {
  readonly ids: UUID[];
};

type BackToAppointedCommandProps = {
  readonly ids: UUID[];
};

type AllocateLimitCommandProps = {
  readonly ids: UUID[];
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

export type CountProps = Omit<AllCommandProps, 'pageNumber'>;

type CountsByStatusesProps = Omit<AllCommandProps, 'pageNumber'>;

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: ESocialPackageTableTab[];
};

type CreateProps = RzdSocialPackageCreateRequest;

type SocialPackageServices = {
  readonly all: (props: AllCommandProps) => Promise<Pageable<RzdSocialPackage>>;
  readonly buildListQueryParams: (props: AllCommandProps) => BuildListQueryParamsReturn;
  readonly archive: (props: ArchiveCommandProps) => Promise<void>;
  readonly activate: (props: ActivateCommandProps) => Promise<void>;
  readonly backToAppointed: (props: BackToAppointedCommandProps) => Promise<void>;
  readonly allocateLimit: (props: AllocateLimitCommandProps) => Promise<void>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: SocialPackageTableTabsCounter; errors: Array<string> }>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: SocialPackageCounterByStatus; errors: Array<string> }>;
  readonly create: (props: CreateProps) => Promise<RzdSocialPackage>;
};

const socialPackageServices: SocialPackageServices = {
  buildListQueryParams: props => {
    const { search, filters, pageNumber, signal, restLimitsExist } = props;
    const { pageSize, sort, statuses } = search;
    const filterDsl = getQueryDslByDataFilterValues(filters);

    const querydsl: ApiQueryDsl = [];

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    if (statuses.length > 0) {
      querydsl.push({
        value: statuses,
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
      });
    }

    return {
      sort,
      signal,
      pageSize,
      restLimitsExist,
      page: pageNumber,
      querydsl,
    };
  },
  all: async props => {
    const response = await Api.socialPackage.all(socialPackageServices.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  archive: async ({ ids, reason, comment }) => {
    await (ids.length === 1
      ? Api.socialPackage.archive({
          id: ids[0],
          reason,
          comment,
        })
      : Api.socialPackage.archiveCollection({ ids, reason, comment }));
  },
  activate: async ({ ids }) => {
    await (ids.length === 1
      ? Api.socialPackage.activate({ id: ids[0] })
      : Api.socialPackage.activateCollection({ ids }));
  },
  backToAppointed: async ({ ids }) => {
    await (ids.length === 1
      ? Api.socialPackage.backToAppointed({ id: ids[0] })
      : Api.socialPackage.backToAppointedCollection({ ids }));
  },
  allocateLimit: async ({ ids, limitToFamilyRest, limitToSelfRest }) => {
    await (ids.length === 1
      ? Api.socialPackage.allocateLimit({
          id: ids[0],
          limitToFamilyRest,
          limitToSelfRest,
        })
      : Api.socialPackage.allocateLimitCollection({ ids, limitToFamilyRest, limitToSelfRest }));
  },
  count: async props => {
    const { data: response } = await Api.socialPackage.all({
      ...socialPackageServices.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: SocialPackageCounterByStatus = {};

    const requests = statuses.map(status => {
      return socialPackageServices.count({ ...props, search: { ...props.search, statuses: [status] }, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: ESocialPackageStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество корпоративных предложений '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: SocialPackageTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForSocialPackageTableTab(tab);
      const restLimit = getRestLimitsExist(tab);

      return socialPackageServices.countsByStatuses({
        ...props,
        restLimitsExist: restLimit,
        search: { ...props.search, statuses },
        signal,
      });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: ESocialPackageTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForSocialPackageTableTab(tab);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество объектов '${tab}': ${response.reason}`);
        }
      }
    };
    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  create: async data => {
    return (await Api.socialPackage.create({ data })).data;
  },
};

export default socialPackageServices;
