import { useSelector } from 'react-redux';
import { BookingOrderAboutOrder } from '../../components/aboutOrder';
import { bookingOrderEditOrderSelector } from '../store/selectors';
import FormItemAdapter from './formItem';
import { Grid } from '@mui/material';

export const BookingOrderEditAboutOrderAdapter = () => {
  const bookingOrder = useSelector(bookingOrderEditOrderSelector);

  if (!bookingOrder) {
    return null;
  }

  return (
    <FormItemAdapter
      gridSize={12}
      title='О заказе'
    >
      <Grid
        item
        xs={4}
      >
        <BookingOrderAboutOrder
          date={bookingOrder.createdAt}
          comment={bookingOrder.customerComment}
        />
      </Grid>
    </FormItemAdapter>
  );
};
