import { EDateTimeFormat } from '@/domain';
import moment from 'moment-timezone';
import { Field, FieldsGroup } from '@components/fields';
import { Wrapper } from '@features/bookingOrder/components/aboutOrder/controls';

type BookingOrderAboutOrderProps = {
  date: string;
  comment: Nullable<string>;
};

export const BookingOrderAboutOrder = ({ date, comment }: BookingOrderAboutOrderProps) => {
  return (
    <Wrapper>
      <FieldsGroup>
        <Field label={'Дата'}>{date ? moment(date).format(EDateTimeFormat.DisplayDefault) : '-'}</Field>
        <Field label={'Комментарий клиента'}>{comment ?? '-'}</Field>
      </FieldsGroup>
    </Wrapper>
  );
};
