import { ApiRequestPageable } from '@/data/api/types';
import { appendQueryDslParams, getOrdersDownloadEndpoint, getQueryDslByDataFilterValues } from '@/data/api/utils';
import {
  BookingOfferService,
  BookingOrder,
  BookingOrderRequest,
  BookingOrderRequestItem,
  BookingOrderStatus,
  BookingServicePriceItem,
  DataFilterQueryDslOperator,
  ENoticeStatus,
  EOfferType,
  EOrderItemStatus,
  EOrderStatus,
  EServiceOrderByDateType,
  ServiceSnapshot,
} from '@/domain';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Notifier from '../../../../system/notifier';
import { BookingOrdersFilterValues } from '../table/filterUtils';
import { BookingOrderItemView, BookingOrderView } from '../types';

enum EBookingOrdersDownloadUrlParam {
  Status = 'status',
  PartnerId = 'partner.id',
  CreatedAt = 'createdAt',
}

export enum EBookingOrderItemsTableColumn {
  Category = 'category',
  Service = 'service',
  PriceItemName = 'priceItemName',
  Cost = 'cost',
  Unit = 'unit',
  BookingDatesAndQty = 'qty',
  TotalCost = 'totalCost',
  Status = 'status',
}

type GetBookingOrdersDownloadUrlWithSkipPaginationProps = {
  readonly skipPageable: true;
} & Partial<ApiRequestPageable>;

type GetBookingOrdersDownloadUrlWithoutSkipPaginationProps = {
  readonly skipPageable?: false;
} & ApiRequestPageable;

type MabeSkipPaginationGetBookingOrdersDownloadUrlProps =
  | GetBookingOrdersDownloadUrlWithSkipPaginationProps
  | GetBookingOrdersDownloadUrlWithoutSkipPaginationProps;

export type GetBookingOrdersDownloadUrlProps = {
  readonly id?: Nullable<UUID[]>;
  readonly partnerId: Nullable<UUID>;
  readonly customerId: Nullable<UUID>;
  readonly states?: Nullable<EOrderStatus[]>;
  readonly date?: [string, string];
  readonly filter?: BookingOrdersFilterValues;
} & MabeSkipPaginationGetBookingOrdersDownloadUrlProps;

export const getBookingOrdersDownloadUrl = (props: GetBookingOrdersDownloadUrlProps) => {
  const { sort, id, partnerId, customerId, states, filter, date, skipPageable, page, pageSize } = props;

  const searchParams = new URLSearchParams();

  searchParams.append('offerType', EOfferType.Booking);

  if (sort) {
    if (typeof sort === 'string') {
      searchParams.append('sort', sort);
    } else {
      sort.forEach(item => searchParams.append('sort', item));
    }
  }

  if (skipPageable) {
    searchParams.append('skipPageable', skipPageable.toString());
  } else {
    page && searchParams.append('page', (page - 1).toString(10));
    pageSize && searchParams.append('size', pageSize.toString(10));
  }

  if (id && id.length > 0) {
    id.forEach(item => searchParams.append('id', item));
  } else {
    const queryDsl = filter ? getQueryDslByDataFilterValues(filter) ?? [] : [];

    if (states) {
      queryDsl.push({
        field: EBookingOrdersDownloadUrlParam.Status,
        operator: DataFilterQueryDslOperator.In,
        value: states,
      });
    }

    if (partnerId) {
      searchParams.append('partnerId', partnerId);
    }

    if (customerId) {
      searchParams.append('customerId', customerId);
    }

    if (date) {
      queryDsl.push({
        field: EBookingOrdersDownloadUrlParam.CreatedAt,
        operator: DataFilterQueryDslOperator.Between,
        value: date,
      });
    }

    appendQueryDslParams(searchParams, queryDsl);
  }

  const search = searchParams.toString() ? `?${searchParams}` : '';

  return `${getOrdersDownloadEndpoint()}${search}`;
};

export const getBookingOrderStatusText = (status: BookingOrderStatus): string => {
  switch (status) {
    case EOrderStatus.New:
      return 'Создан';
    case EOrderStatus.Cancelled:
      return 'Отменён';
    case EOrderStatus.Given:
      return 'Выполнен';
    case EOrderStatus.Confirmed:
      return 'Принят';
  }
};

export const getBookingOrderItemStatusName = (status: EOrderItemStatus): string => {
  switch (status) {
    case EOrderItemStatus.New:
      return 'Новый';
    case EOrderItemStatus.Confirmed:
      return 'Подтвержден';
    case EOrderItemStatus.Returned:
      return 'Возвращен полностью';
    case EOrderItemStatus.Cancelled:
      return 'Отменен';
    case EOrderItemStatus.Given:
      return 'Получен клиентом';
    case EOrderItemStatus.Changed:
      return 'Состав позиции изменен';
    case EOrderItemStatus.PartiallyReceived:
      return 'Частично получен клиентом';
  }
};

export const bookingOrderItemTableStatuses = [
  EOrderItemStatus.New,
  EOrderItemStatus.Confirmed,
  EOrderItemStatus.Cancelled,
];

const convertBookingOfferServiceToSnapshot = (service: BookingOfferService): ServiceSnapshot => {
  const { id, name, description, category, orderByDateType } = service;
  return { id, name, description, category, orderByDateType };
};
export const getEmptyBookingOrderItemView = (
  service: BookingOfferService,
  priceItem: BookingServicePriceItem
): BookingOrderItemView => {
  return {
    id: null,
    totalCost: null,
    priceItem: {
      id: priceItem.id,
      name: priceItem.name,
      price: priceItem.price,
      unit: { name: priceItem.unit?.name ?? null },
      service: convertBookingOfferServiceToSnapshot(service),
    },
    slots: null,

    offer: null,
    originalCost: priceItem.price, // Общая стоимость в рублях без скидки
    cost: priceItem.price, // Общая стоимость со скидкой в рублях
    discount: null, // Значение общей скидки в рублях
    name: null, // Название позиции заказа
    image: null, // изображение элемента заказа
    qty: null, // Количество единиц в позиции
    status: EOrderItemStatus.New,
    internalId: uuidv4(),
  };
};

const convertBookingOrderItemViewToRequest = (orderItem: BookingOrderItemView): BookingOrderRequestItem => {
  const { id, cost, priceItem, qty, slots, status } = orderItem;
  return { id, price: cost, priceItem, qty, slots, status };
};

export const convertBookingOrderViewToRequest = (order: BookingOrderView): BookingOrderRequest => {
  const { status, managerComment, orderItems } = order;
  return {
    status,
    managerComment,
    items: orderItems.map(item => convertBookingOrderItemViewToRequest(item)),
  };
};

export const convertBookingOrderToBookingOrderView = (order: BookingOrder): BookingOrderView => {
  const {
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    id,
    offer,
    partner,
    status,
    number,
    cost,
    customer,
    lastStatusComment,
    cancelReason,
    customerCancelReason,
    customerComment,
    managerComment,
    orderItems,
    hasRzdSocialPackage,
    customerRzdSocialPackage,
  } = order;

  return {
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    id,
    offer,
    partner,
    status,
    number,
    cost,
    customer,
    lastStatusComment,
    cancelReason,
    customerCancelReason,
    customerComment,
    managerComment,
    hasRzdSocialPackage,
    customerRzdSocialPackage,
    orderItems: orderItems?.map(orderItem => ({ ...orderItem, internalId: orderItem.id })) ?? [],
  };
};

export const getBookingOrderItemTotalCost = (orderItem: BookingOrderItemView): number => {
  const cost = orderItem.cost ?? 0;
  if (orderItem.priceItem.service.orderByDateType) {
    switch (orderItem.priceItem.service.orderByDateType) {
      case EServiceOrderByDateType.None:
        return cost * (orderItem.qty ?? 0);
      case EServiceOrderByDateType.Days:
        return cost * (orderItem.slots?.reduce<number>((prev, cur) => prev + cur.qty, 0) ?? 0);
      case EServiceOrderByDateType.Period:
        return (
          cost *
          (orderItem.slots?.[0]
            ? orderItem.slots[0].qty * moment(orderItem.slots[0].endDate).diff(orderItem.slots[0].startDate, 'days')
            : 0)
        );
    }
  }
  return 0;
};

export const showBookingOrderInvalidNotification = (order: BookingOrderView, nextStatus?: BookingOrderStatus) => {
  let text;

  if (!nextStatus) {
    text = 'Заполните корректно поля, чтобы сохранить заказ';
  } else {
    switch (nextStatus) {
      case EOrderStatus.Confirmed:
        text = 'Заполните все обязательные поля, чтобы перевести заказ в принятые';
        break;
      case EOrderStatus.Given:
        text = 'Заполните все обязательные поля, чтобы перевести заказ в выполненные';
        break;
      default:
        text = 'Заполните все обязательные поля, чтобы сохранить изменения';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Warning, text);
};

export const getBookingOrderCost = (order: BookingOrderView): number => {
  return order.orderItems
    ?.filter(item => item.status !== EOrderItemStatus.Cancelled)
    .reduce<number>((acc, item) => acc + (item.totalCost ?? 0), 0);
};
