import { UserAccessMatrix, UserAccessMatrixCommon } from '@/domain/model/accessMatrix';
import {
  EBannerPartition,
  EOrderPartition,
  EPartnerPermission,
  EPartnerStatus,
  ESecuredPartition,
  EUserRole,
} from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import { AppUserSpecific } from '@/domain/model/user';
import rootRouting from '@/routing';
import { FeaturesOptions } from '@/system/appConfigurator';
import { EAppFeature } from '../../types';

type BuildUserAccessMatrixType = (
  user: AppUserSpecific,
  roles: EUserRole[],
  hasRole: (...role: EUserRole[]) => boolean,
  hasFeature: (feature: EAppFeature) => boolean,
  getSystemFeatureOption: <T extends keyof FeaturesOptions>(feature: T) => Nullable<FeaturesOptions[T]>
) => UserAccessMatrix;

type BuildUserAccessMatrixCommonType = (
  roles: EUserRole[],
  hasRole: (...role: EUserRole[]) => boolean
) => UserAccessMatrixCommon;

type OverrideUserAccessMatrixType = (
  matrix: UserAccessMatrix,
  user: AppUserSpecific,
  roles: EUserRole[],
  hasRole: (...role: EUserRole[]) => boolean,
  getSystemFeatureOption: <T extends keyof FeaturesOptions>(feature: T) => Nullable<FeaturesOptions[T]>
) => UserAccessMatrix;

type GetUserDefaultRouteType = (hasRole: (...role: EUserRole[]) => boolean) => string;

type UserPartnerSpecificRestrictions = {
  readonly isDraftPartner: boolean;
  readonly isNotVerifiedPartner: boolean;
  readonly isRejectedPartner: boolean;
  readonly isActivePartner: boolean;
  readonly accessToTradeOffers: boolean;
  readonly accessToCorpOffers: boolean;
  readonly accessToProductOffers: boolean;
  readonly accessToBookingOffers: boolean;
};

type GetUserPartnerRestrictionsType = (
  user: AppUserSpecific,
  roles: EUserRole[],
  hasRole: (...role: EUserRole[]) => boolean
) => Nullable<UserPartnerSpecificRestrictions>;

export const getAllowedUserRoles = (roles: string[]) => {
  return (roles as EUserRole[]).filter(existed => Object.values(EUserRole).some(localRole => localRole === existed));
};

const getUserDefaultRoute: GetUserDefaultRouteType = hasRole => {
  if (hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin)) {
    return rootRouting.events;
  } else if (hasRole(EUserRole.AdminMp)) {
    return rootRouting.partners;
  } else if (hasRole(EUserRole.AdminMpCorp)) {
    return rootRouting.corpOffers;
  } else if (hasRole(EUserRole.ComplaintAdmin)) {
    return rootRouting.complaints;
  } else if (hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner)) {
    return rootRouting.tradeOffers;
  }
  return '';
};

const getUserPartnerRestrictions: GetUserPartnerRestrictionsType = (user, roles, hasRole) => {
  if (!user.mpPartner?.partner || !hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner)) {
    return null;
  }

  const partner = user.mpPartner.partner;

  const tradeOffersAllowed: boolean = partner.permissions?.includes(EPartnerPermission.TradeOfferPublic) ?? false;
  const corpOffersAllowed: boolean = partner.permissions?.includes(EPartnerPermission.CorpOfferPublic) ?? false;
  const productOffersAllowed: boolean = partner.permissions?.includes(EPartnerPermission.ProductOfferPublic) ?? false;
  const bookingOffersAllowed: boolean = partner.permissions?.includes(EPartnerPermission.BookingOfferPublic) ?? false;

  return {
    isDraftPartner: partner.status === EPartnerStatus.Unverified,
    isNotVerifiedPartner: [EPartnerStatus.Unverified, EPartnerStatus.ToVerification].includes(partner.status),
    isRejectedPartner: partner.status === EPartnerStatus.Rejected,
    isActivePartner: partner.status === EPartnerStatus.Enabled,
    accessToTradeOffers: tradeOffersAllowed,
    accessToCorpOffers: corpOffersAllowed,
    accessToProductOffers: productOffersAllowed,
    accessToBookingOffers: bookingOffersAllowed,
  };
};

export const overrideUserAccessMatrix: OverrideUserAccessMatrixType = (
  matrix,
  user,
  roles,
  hasRole,
  getSystemFeatureOption
) => {
  let newMatrix: UserAccessMatrix = { ...matrix };

  const userPartnerRestrictions = getUserPartnerRestrictions(user, roles, hasRole);
  if (userPartnerRestrictions) {
    const {
      isNotVerifiedPartner,
      isRejectedPartner,
      isActivePartner,
      accessToTradeOffers,
      accessToCorpOffers,
      accessToProductOffers,
      accessToBookingOffers,
    } = userPartnerRestrictions;

    newMatrix = {
      ...newMatrix,
      [ESecuredPartition.ProductOffers]: {
        ...newMatrix[ESecuredPartition.ProductOffers],
        delete: newMatrix[ESecuredPartition.ProductOffers].delete && isActivePartner,
        archive: newMatrix[ESecuredPartition.ProductOffers].archive && isActivePartner && accessToProductOffers,
        pause: newMatrix[ESecuredPartition.ProductOffers].pause && isActivePartner && accessToProductOffers,
        resume: newMatrix[ESecuredPartition.ProductOffers].resume && isActivePartner && accessToProductOffers,
        publish: newMatrix[ESecuredPartition.ProductOffers].publish && isActivePartner && accessToProductOffers,
        inWork: newMatrix[ESecuredPartition.ProductOffers].inWork && isActivePartner && accessToProductOffers,
        unPublish: newMatrix[ESecuredPartition.ProductOffers].unPublish && isActivePartner && accessToProductOffers,
      },
      [ESecuredPartition.TradeOffers]: {
        ...newMatrix[ESecuredPartition.TradeOffers],
        delete: newMatrix[ESecuredPartition.TradeOffers].delete && isActivePartner,
        archive: newMatrix[ESecuredPartition.TradeOffers].archive && isActivePartner && accessToTradeOffers,
        pause: newMatrix[ESecuredPartition.TradeOffers].pause && isActivePartner && accessToTradeOffers,
        resume: newMatrix[ESecuredPartition.TradeOffers].resume && isActivePartner && accessToTradeOffers,
        publish: newMatrix[ESecuredPartition.TradeOffers].publish && isActivePartner && accessToTradeOffers,
        unPublish: newMatrix[ESecuredPartition.TradeOffers].unPublish && isActivePartner && accessToTradeOffers,
        upload: newMatrix[ESecuredPartition.TradeOffers].upload && isActivePartner,
        retrieve: newMatrix[ESecuredPartition.TradeOffers].retrieve && isActivePartner,
      },
      [ESecuredPartition.CorpOffers]: {
        ...newMatrix[ESecuredPartition.CorpOffers],
        delete: newMatrix[ESecuredPartition.CorpOffers].delete && isActivePartner,
        archive: newMatrix[ESecuredPartition.CorpOffers].archive && isActivePartner && accessToCorpOffers,
        pause: newMatrix[ESecuredPartition.CorpOffers].pause && isActivePartner && accessToCorpOffers,
        resume: newMatrix[ESecuredPartition.CorpOffers].resume && isActivePartner && accessToCorpOffers,
        publish: newMatrix[ESecuredPartition.CorpOffers].publish && isActivePartner && accessToCorpOffers,
        unPublish: newMatrix[ESecuredPartition.CorpOffers].unPublish && isActivePartner && accessToCorpOffers,
        upload: newMatrix[ESecuredPartition.CorpOffers].upload && isActivePartner,
      },
      [ESecuredPartition.BookingOffers]: {
        ...newMatrix[ESecuredPartition.BookingOffers],
        delete: newMatrix[ESecuredPartition.BookingOffers].delete && isActivePartner,
        archive: newMatrix[ESecuredPartition.BookingOffers].archive && isActivePartner && accessToBookingOffers,
        pause: newMatrix[ESecuredPartition.BookingOffers].pause && isActivePartner && accessToBookingOffers,
        resume: newMatrix[ESecuredPartition.BookingOffers].resume && isActivePartner && accessToBookingOffers,
        publish: newMatrix[ESecuredPartition.BookingOffers].publish && isActivePartner && accessToBookingOffers,
        manageServices:
          newMatrix[ESecuredPartition.BookingOffers].manageServices && isActivePartner && accessToBookingOffers,
        retrieve: newMatrix[ESecuredPartition.BookingOffers].retrieve && isActivePartner && accessToBookingOffers,
        unPublish: newMatrix[ESecuredPartition.BookingOffers].unPublish && isActivePartner && accessToBookingOffers,
        upload: newMatrix[ESecuredPartition.BookingOffers].upload && isActivePartner,
      },
      [ESecuredPartition.Partners]: {
        ...newMatrix[ESecuredPartition.Partners],
        edit: newMatrix[ESecuredPartition.Partners].edit && (isNotVerifiedPartner || isRejectedPartner),
      },
      [ESecuredPartition.PartnerNotifications]: {
        ...newMatrix[ESecuredPartition.PartnerNotifications],
        edit: newMatrix[ESecuredPartition.PartnerNotifications].edit && isActivePartner,
      },
      [ESecuredPartition.Orders]: {
        ...newMatrix[ESecuredPartition.Orders],
        [EOrderPartition.ProductOffers]: {
          ...newMatrix[ESecuredPartition.Orders][EOrderPartition.ProductOffers],
          create: newMatrix[ESecuredPartition.Orders][EOrderPartition.ProductOffers].create && isActivePartner,
          edit: newMatrix[ESecuredPartition.Orders][EOrderPartition.ProductOffers].edit && isActivePartner,
        },
        [EOrderPartition.BookingOffers]: {
          ...newMatrix[ESecuredPartition.Orders][EOrderPartition.BookingOffers],
          create: newMatrix[ESecuredPartition.Orders][EOrderPartition.BookingOffers].create && isActivePartner,
          edit: newMatrix[ESecuredPartition.Orders][EOrderPartition.BookingOffers].edit && isActivePartner,
        },
      },
    };
  }
  return newMatrix;
};

export const buildUserAccessMatrix: BuildUserAccessMatrixType = (
  user,
  roles,
  hasRole,
  hasFeature,
  getSystemFeatureOption
) => {
  const matrixCommon: UserAccessMatrixCommon = buildUserAccessMatrixCommon(roles, hasRole);

  const matrix: UserAccessMatrix = {
    ...matrixCommon,

    [ESecuredPartition.Partners]: {
      view: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp
      ),
      viewAsAdmin: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
      create: hasRole(EUserRole.AdminMp),
      edit: hasRole(EUserRole.AdminPartner, EUserRole.AdminMp),
      activate: hasRole(EUserRole.AdminMp),
      deactivate: hasRole(EUserRole.AdminMp),
      sendToVerification: hasRole(EUserRole.AdminPartner),
      returnToVerification: hasRole(EUserRole.AdminPartner, EUserRole.AdminMp),
      verify: hasRole(EUserRole.AdminMp),
      approve: hasRole(EUserRole.AdminMp),
      reject: hasRole(EUserRole.AdminMp),
      deleteDraft: hasRole(EUserRole.AdminMp),
      changePermissions: hasRole(EUserRole.AdminMp),
    },
    [ESecuredPartition.PartnerDesks]: {
      view: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner),
    },
    [ESecuredPartition.PartnerNotifications]: {
      view: true,
      edit: hasRole(EUserRole.AdminPartner),
    },
    [ESecuredPartition.Customers]: {
      view: {
        list: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
        details: hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner,
          EUserRole.AdminMpCorp
        ),
        management: hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner,
          EUserRole.AdminMpCorp
        ),
      },
      changeStatus: hasRole(EUserRole.AdminMp),
    },
    [ESecuredPartition.Orders]: {
      [EOrderPartition.ProductOffers]: {
        view:
          hasFeature(EAppFeature.ProductOffer) &&
          hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminPartner, EUserRole.ManagerPartner),
        save: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        create: false,
        edit: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        give: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        cancel: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        renew: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        receive: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        pay: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        send: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        return: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        partiallyReturn: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        confirm: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      },
      [EOrderPartition.BookingOffers]: {
        view: hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner,
          EUserRole.AdminMpCorp
        ),
        create: false,
        save: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        edit: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        give: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        cancel: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        confirm: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        renew: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
        download: hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner,
          EUserRole.AdminMpCorp
        ),
      },
    },
    [ESecuredPartition.TradeActivations]: {
      view:
        hasFeature(EAppFeature.Activations) &&
        hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminMpCorp,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner
        ),
    },
    [ESecuredPartition.CorpActivations]: {
      view:
        hasFeature(EAppFeature.Activations) &&
        hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminMpCorp,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner
        ),
      give: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      unGive: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
    },
    [ESecuredPartition.ProductOffers]: {
      view:
        hasFeature(EAppFeature.ProductOffer) &&
        hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner,
          EUserRole.AdminMpCorp
        ),
      create: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      duplicate: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      viewAsAdmin: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
      delete: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      publish: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      unPublish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      changePrice: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      changeStock: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      moderate: hasRole(EUserRole.AdminMp),
      approve: hasRole(EUserRole.AdminMp),
      reject: hasRole(EUserRole.AdminMp),
      inWork: hasRole(EUserRole.AdminMp),
      download: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      upload: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      changeCategory: hasRole(EUserRole.AdminPartner),
      viewHistory: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminMpCorp,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner
      ),
    },
    [ESecuredPartition.TradeOffers]: {
      view: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp
      ),
      create: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      duplicate: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      viewAsAdmin: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
      delete: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      publish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      unPublish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      moderate: hasRole(EUserRole.AdminMp),
      approve: hasRole(EUserRole.AdminMp),
      reject: hasRole(EUserRole.AdminMp),
      download: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpCorp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner
      ),
      upload: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      changePromotionStatus: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      editPromotions: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      retrieve: hasRole(EUserRole.AdminMp),
      changeModerator: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      returnToVerification: hasRole(EUserRole.AdminMp),
      viewHistory: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminMpCorp,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner
      ),
    },
    [ESecuredPartition.TradeCategories]: {
      view: true,
      edit: hasRole(EUserRole.AdminMp),
      create: hasRole(EUserRole.AdminMp),
      delete: hasRole(EUserRole.AdminMp),
    },
    [ESecuredPartition.CorpOffers]: {
      view: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp
      ),
      create: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      duplicate: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      viewAsAdmin: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
      delete: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      publish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      unPublish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      moderate: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      approve: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      reject: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      download: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp
      ),
      upload: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      changePromotionStatus: hasRole(
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMp,
        EUserRole.AdminMpCorp
      ),
      editPromotions: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp
      ),
      retrieve: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      canViewApprovalRegistry: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      canEditApprovalRegistry: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      canEditResponsiblePerson: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      changeModerator: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      viewHistory: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminMpCorp,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner
      ),
    },
    [ESecuredPartition.CorpCategories]: {
      view: true,
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      create: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      delete: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
    [ESecuredPartition.BookingOffers]: {
      view:
        hasFeature(EAppFeature.Booking) &&
        hasRole(
          EUserRole.AdminMp,
          EUserRole.AdminMpReadOnly,
          EUserRole.AdminPartner,
          EUserRole.ManagerPartner,
          EUserRole.AdminMpCorp
        ),
      create: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      viewAsAdmin: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
      duplicate: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      delete: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      publish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      manageServices: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      unPublish: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      moderate: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      changeModerator: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      approve: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      reject: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      retrieve: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      download: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp
      ),
      upload: hasRole(EUserRole.AdminPartner, EUserRole.ManagerPartner),
      viewHistory: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminMpCorp,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner
      ),
    },
    [ESecuredPartition.PartnerEmployees]: {
      view: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner),
      create: hasRole(EUserRole.AdminPartner),
      changeStatus: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner),
      changeResponsible: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner),
    },
    [ESecuredPartition.AdminsMp]: {
      view: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
      create: hasRole(EUserRole.AdminMp),
      changeStatus: hasRole(EUserRole.AdminMp),
    },
    [ESecuredPartition.Reports]: {
      view: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp,
        EUserRole.SystemAdmin,
        EUserRole.EventAdmin
      ),
      execute: hasRole(
        EUserRole.AdminMp,
        EUserRole.AdminMpReadOnly,
        EUserRole.AdminPartner,
        EUserRole.ManagerPartner,
        EUserRole.AdminMpCorp,
        EUserRole.SystemAdmin,
        EUserRole.EventAdmin
      ),
    },
    [ESecuredPartition.Banners]: {
      [EBannerPartition.TradeOffers]: {
        view: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
        edit: hasRole(EUserRole.AdminMp),
        create: hasRole(EUserRole.AdminMp),
        activate: hasRole(EUserRole.AdminMp),
        pause: hasRole(EUserRole.AdminMp),
        resume: hasRole(EUserRole.AdminMp),
        archive: hasRole(EUserRole.AdminMp),
        changeSortIndex: hasRole(EUserRole.AdminMp),
      },
      [EBannerPartition.CorpOffers]: {
        view: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp),
        edit: hasRole(EUserRole.AdminMp),
        create: hasRole(EUserRole.AdminMp),
        activate: hasRole(EUserRole.AdminMp),
        pause: hasRole(EUserRole.AdminMp),
        resume: hasRole(EUserRole.AdminMp),
        archive: hasRole(EUserRole.AdminMp),
        changeSortIndex: hasRole(EUserRole.AdminMp),
      },
    },
    [ESecuredPartition.Configuration]: {
      view: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly, EUserRole.AdminMpCorp, EUserRole.SystemAdmin),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminMpReadOnly),
    },
    [ESecuredPartition.Events]: {
      view: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      edit: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      create: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      publish: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      publishAnnounce: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      cancel: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      editResults: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      publishResults: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      editMembers: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      alterOwnOnly: hasRole(EUserRole.EventAdmin),
    },
    [ESecuredPartition.Playgrounds]: {
      view: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      edit: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      create: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin),
      block: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      unblock: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      alterOwnOnly: hasRole(EUserRole.EventAdmin),
    },
    [ESecuredPartition.Complaints]: {
      view: hasRole(EUserRole.SystemAdmin, EUserRole.ComplaintAdmin),
      moderate: hasRole(EUserRole.SystemAdmin, EUserRole.ComplaintAdmin),
      alterOwnOnly: hasRole(EUserRole.ComplaintAdmin),
    },
    [ESecuredPartition.Teams]: {
      view: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
      create: hasRole(EUserRole.SystemAdmin),
      edit: hasRole(EUserRole.SystemAdmin),
      changeMembers: hasRole(EUserRole.SystemAdmin),
    },
    [ESecuredPartition.Chats]: {
      view: hasRole(EUserRole.SystemAdmin, EUserRole.EventAdmin, EUserRole.ComplaintAdmin),
    },
    [ESecuredPartition.Cms]: {
      view: hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
    },
    [ESecuredPartition.PartnerWindow]: {
      view:
        hasFeature(EAppFeature.CmsPartner) &&
        hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      create: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      publish: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      delete: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      changeSortIndex: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      changeOfferIconVisible: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      changePageVisible: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
    },
    [ESecuredPartition.LandingWindow]: {
      view: hasFeature(EAppFeature.CmsLanding) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      create: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      publish: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      delete: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      changeSortIndex: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      changeOfferIconVisible: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
    [ESecuredPartition.ActivityTypes]: {
      view: true,
      edit: hasRole(EUserRole.SystemAdmin),
      create: hasRole(EUserRole.SystemAdmin),
      changeVisible: hasRole(EUserRole.SystemAdmin),
      delete: getSystemFeatureOption('activityType')?.canDictionaryDelete && hasRole(EUserRole.SystemAdmin),
      sort: hasRole(EUserRole.SystemAdmin),
    },
    [ESecuredPartition.BookingOfferCategories]: {
      view: hasFeature(EAppFeature.Booking),
      edit: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      create: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      delete: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
    [ESecuredPartition.BookingServiceCategories]: {
      view: hasFeature(EAppFeature.Booking),
      edit: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      create: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      delete: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
    [ESecuredPartition.BookingOfferPriceUnits]: {
      view: hasFeature(EAppFeature.Booking),
      edit: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      create: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      delete: hasFeature(EAppFeature.Booking) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
    [ESecuredPartition.AnalyticsQueries]: {
      view:
        hasFeature(EAppFeature.QueryAnalytics) &&
        hasRole(EUserRole.AdminMp, EUserRole.AdminPartner, EUserRole.ManagerPartner, EUserRole.AdminMpCorp),
      download: true,
    },
    [ESecuredPartition.AdCampaigns]: {
      view:
        hasFeature(EAppFeature.AdCampaign) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner),
      edit: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      create: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      archive: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      pause: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      resume: hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
    [ESecuredPartition.ClientOrgs]: {
      view: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      edit: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp),
      create: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp),
      activate: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp),
      deactivate: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp),
    },
    [ESecuredPartition.ClientOrgsUsers]: {
      view: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      edit: false,
      create: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp),
      download: hasFeature(EAppFeature.Saas) && hasRole(EUserRole.AdminMp),
    },
    [ESecuredPartition.SocialPackages]: {
      view: hasFeature(EAppFeature.Csp) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminMpReadOnly),
      create: hasFeature(EAppFeature.CspCreate) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      activate: hasFeature(EAppFeature.Csp) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      allocateLimit: hasFeature(EAppFeature.Csp) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      backToAppointed: hasFeature(EAppFeature.Csp) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
      archive: hasFeature(EAppFeature.Csp) && hasRole(EUserRole.AdminMp, EUserRole.AdminMpCorp),
    },
  };

  return overrideUserAccessMatrix(matrix, user, roles, hasRole, getSystemFeatureOption);
};

const hasRole = (userRoles: EUserRole[], requestedRoles: EUserRole[]) =>
  userRoles?.some(role => requestedRoles.some(r => r === role)) ?? false;

export const isSportUser = (userRoles: EUserRole[]) => {
  return hasRole(userRoles, [EUserRole.EventAdmin, EUserRole.SystemAdmin, EUserRole.ComplaintAdmin]);
};

export const buildUserAccessMatrixCommon: BuildUserAccessMatrixCommonType = (roles, hasRole) => {
  const defaultRoute = getUserDefaultRoute(hasRole);

  const isMpUser = hasRole(
    EUserRole.AdminMp,
    EUserRole.AdminMpReadOnly,
    EUserRole.AdminPartner,
    EUserRole.ManagerPartner,
    EUserRole.DefaultMp
  );
  const isAdminMp = hasRole(EUserRole.AdminMp);
  const isAdminPartner = hasRole(EUserRole.AdminPartner);
  const isManagerPartner = hasRole(EUserRole.ManagerPartner);
  const isPartnerUser = isAdminPartner || isManagerPartner;
  const isAdminSport = hasRole(EUserRole.EventAdmin, EUserRole.SystemAdmin, EUserRole.ComplaintAdmin);
  const isSystemAdminSport = hasRole(EUserRole.SystemAdmin);
  const isSportUser = isSystemAdminSport || isAdminSport;
  const isAdminSportEvent = hasRole(EUserRole.EventAdmin);

  const matrix: UserAccessMatrixCommon = {
    defaultRoute,
    roles,

    isMpUser,
    isSportUser,
    isPartnerUser,
    isAdminMp,
    isAdminPartner,
    isManagerPartner,
    isAdminSport,
    isAdminSportEvent,
    isSystemAdminSport,
  };

  return matrix;
};
