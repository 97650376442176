import { Typography } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import React, { ChangeEvent, MouseEvent, MouseEventHandler, useMemo, useState } from 'react';
import { ESortDirection } from '@/domain/model/enums';
import { Nullable } from '@/domain/model/types';
import DataTableCellSettings from '../cell/settings';
import {
  HeadCellWrapper,
  TableCheckbox,
  TableHeadCheckboxCell,
  TableMultiCellContainer,
  TableSortLabel,
} from '../controls';
import {
  ColumnActionsPosition,
  DataTableColumns,
  DataTableDefaultComponents,
  DataTableSort,
  ETableAllSelectedMode,
  ETableColumnAlign,
} from '../index';

interface HeadCellProps {
  readonly content: any;
  readonly sortable?: any;
  readonly sortActive?: any;
  readonly align?: ETableColumnAlign;
  readonly width?: number | string;
  readonly colSpan?: number;
  readonly rowSpan?: number;
  readonly fixed?: boolean;
  readonly position?: ColumnActionsPosition;
  readonly positionOffset?: number;
  readonly component: React.ElementType<
    TableCellProps & {
      readonly fixed?: boolean;
      readonly position?: ColumnActionsPosition;
      readonly positionOffset?: number;
    }
  >;
  readonly sortDirection?: ESortDirection;
  readonly onSortClick?: MouseEventHandler<HTMLSpanElement>;
  readonly children?: any;
}

const HeadCell = (props: HeadCellProps) => {
  const {
    content,
    sortable,
    align,
    width,
    component,
    sortActive,
    sortDirection,
    colSpan,
    rowSpan,
    fixed,
    position,
    positionOffset,
    children,
    onSortClick,
  } = props;

  const Component = component;

  const widthCell = useMemo(() => {
    if (Array.isArray(content)) {
      return Number.parseFloat((width as string) ?? '10rem') * content.length + 'rem';
    }
    return width;
  }, [width, content]);

  const title = useMemo(() => {
    if (Array.isArray(content)) {
      return (
        <TableMultiCellContainer
          width={Number.parseFloat(widthCell as string)}
          widthElement={Number.parseFloat((width as string) || '10rem')}
        >
          {content.map((item, index) => (
            <Typography
              variant='body2'
              key={index + item}
            >
              {item}
            </Typography>
          ))}
        </TableMultiCellContainer>
      );
    }

    return <Typography variant='body2'>{content}</Typography>;
  }, [content, widthCell, width]);

  return (
    <Component
      align={align}
      width={widthCell}
      colSpan={colSpan}
      rowSpan={rowSpan}
      fixed={fixed}
      position={position}
      positionOffset={positionOffset}
    >
      {sortable === undefined ? (
        <HeadCellWrapper>{title}</HeadCellWrapper>
      ) : (
        <TableSortLabel
          visible={sortable}
          active={sortActive}
          direction={sortDirection}
          onClick={onSortClick}
        >
          <HeadCellWrapper active={sortable}>{title}</HeadCellWrapper>
        </TableSortLabel>
      )}
      {children}
    </Component>
  );
};

interface HeadProps {
  readonly columns: DataTableColumns;
  readonly sort?: DataTableSort;
  readonly selectable?: boolean;
  readonly selectedMode?: ETableAllSelectedMode;
  readonly fixColumnActions?: boolean;
  readonly columnActionsPosition: ColumnActionsPosition;
  readonly rowActions?: boolean;
  readonly onSort?: (event: MouseEvent<HTMLSpanElement, MouseEvent>, column: string, direction: ESortDirection) => void;
  readonly onAllRowsSelect?: Nullable<(event: ChangeEvent) => void>;
  readonly onChangeMetadata?: () => void;
  readonly children?: any;
}

export const Head = (props: HeadProps) => {
  const {
    columns,
    sort,
    onSort,
    selectable,
    selectedMode,
    fixColumnActions,
    columnActionsPosition = 'end',
    rowActions,
    onAllRowsSelect,
    onChangeMetadata,
    children,
  } = props;

  const [actionsPositionOffset, setActionsPositionOffset] = useState<number>(0);
  const isSorted = (key: string) => sort?.column === key;

  const isShowActions = rowActions || !!onChangeMetadata;

  const actionCell = useMemo(
    () => (
      <HeadCell
        content={<DataTableCellSettings onClick={onChangeMetadata} />}
        align={ETableColumnAlign.Center}
        width='3rem'
        fixed={fixColumnActions}
        positionOffset={actionsPositionOffset}
        position={columnActionsPosition}
        component={DataTableDefaultComponents.HeadCell}
      />
    ),
    [onChangeMetadata, actionsPositionOffset]
  );

  const setActionsPositionRelative = (el: HTMLTableDataCellElement) => {
    if (!!el?.clientWidth && el?.clientWidth > actionsPositionOffset && columnActionsPosition === 'start') {
      setActionsPositionOffset(el.clientWidth);
    }
  };

  return (
    <DataTableDefaultComponents.Head>
      <DataTableDefaultComponents.HeadRow>
        {selectable && (
          <TableHeadCheckboxCell
            ref={setActionsPositionRelative}
            width='2.3rem'
            fixed={fixColumnActions && columnActionsPosition === 'start'}
            position={'start'}
          >
            {onAllRowsSelect && (
              <TableCheckbox
                color='primary'
                checked={selectedMode === ETableAllSelectedMode.Any || selectedMode === ETableAllSelectedMode.All}
                indeterminate={selectedMode === ETableAllSelectedMode.Any}
                onChange={onAllRowsSelect}
              />
            )}
          </TableHeadCheckboxCell>
        )}

        {columnActionsPosition === 'start' && isShowActions && actionCell}

        {Object.keys(columns).map((key: string) => {
          const column = columns[key];

          return column && !column.hidden ? (
            <HeadCell
              key={key}
              content={column.title}
              align={column.align}
              width={column.width}
              colSpan={column.colspan}
              rowSpan={column.rowspan}
              sortable={column.sortable}
              sortActive={isSorted(key)}
              sortDirection={isSorted(key) ? sort?.direction : ESortDirection.Asc}
              onSortClick={
                column.sortable && onSort
                  ? (event: any) =>
                      onSort(
                        event,
                        key,
                        sort?.direction === ESortDirection.Asc ? ESortDirection.Desc : ESortDirection.Asc
                      )
                  : undefined
              }
              component={DataTableDefaultComponents.HeadCell}
            />
          ) : null;
        })}

        {columnActionsPosition === 'end' && isShowActions && actionCell}
      </DataTableDefaultComponents.HeadRow>
      {children}
    </DataTableDefaultComponents.Head>
  );
};
