import Api from '@/data/api';
import {
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
  pageSizeAll,
} from '@/domain';
import { EReportOffersStatisticByPartnerFilterItem } from '@features/report/downloads/reports/offersStatistic';

export enum EBookingOrdersItemsFunnelFilterItem {
  DatePeriod = 'datePeriod',
  Partner = 'partner',
}

export type ReportBookingOrdersItemsFilterEditStrategy = DataFilterStrategyBase<EBookingOrdersItemsFunnelFilterItem>;

export type ReportBookingOrdersItemsFilterValues = {
  readonly [EBookingOrdersItemsFunnelFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportOffersStatisticByPartnerFilterItem.Partner]?: DataFilterValueItem<
    Nullable<DataFilterStrategyListItem>
  >;
};

export const getReportBookingOrdersItemsFunnelFilterStrategies = (
  values: ReportBookingOrdersItemsFilterValues,
  partnerId: Nullable<UUID>
): ReportBookingOrdersItemsFilterEditStrategy[] => {
  const strategies: ReportBookingOrdersItemsFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EBookingOrdersItemsFunnelFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EBookingOrdersItemsFunnelFilterItem.DatePeriod]?.value || null,
  });

  if (!partnerId) {
    strategies.push({
      type: EDataFilterType.AutocompleteSingle,
      key: EBookingOrdersItemsFunnelFilterItem.Partner,
      label: 'Партнёр',
      preview: `Партнер (${values[EBookingOrdersItemsFunnelFilterItem.Partner]?.value?.name})` || null,
      value: values[EBookingOrdersItemsFunnelFilterItem.Partner]?.value || null,
      source: (name: string) =>
        name
          ? Api.partner
              .all({
                query: name,
                sort: 'name,asc',
                page: 1,
                pageSize: pageSizeAll,
              })
              .then(response => response.data)
          : Promise.resolve([]),
    });
  }

  return strategies;
};
