import { EReportType } from '@features/report/types';
import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type ReportDownloadsState = {
  readonly reports: Record<EReportType, Nullable<{ readonly partnerId: Nullable<UUID> }>>;
};

interface Reducers extends SliceCaseReducers<ReportDownloadsState> {
  reportDownloadsChangeDialogState: Reducer<{
    name: keyof ReportDownloadsState['reports'];
    data: Nullable<{ readonly partnerId: Nullable<UUID> }>;
  }>;
}

type Reducer<T = undefined> = CaseReducer<ReportDownloadsState, PayloadAction<T>>;

const slice = createSlice<ReportDownloadsState, Reducers>({
  name: 'report/downloads',
  initialState: {
    reports: {
      [EReportType.Offers]: null,
      [EReportType.Partners]: null,
      [EReportType.OffersStatistic]: null,
      [EReportType.Events]: null,
      [EReportType.EventsDetailed]: null,
      [EReportType.Users]: null,
      [EReportType.SportUsers]: null,
      [EReportType.BookingOrdersStatistic]: null,
      [EReportType.SocialPackages]: null,
      [EReportType.BookingOrdersItems]: null,
      [EReportType.BookingOrdersServices]: null,
      [EReportType.BookingOrdersFunnel]: null,
    },
  },
  reducers: {
    reportDownloadsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.reports[name] = data;
    },
  },
  extraReducers: builder => {},
});

export const { reportDownloadsChangeDialogState } = slice.actions;

export default slice.reducer;
