import { Nullable, Pageable, RzdSocialPackage, UUID } from '@/domain';
import { PaginationSize } from '@/presentation/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ESocialPackageTableColumn, ESocialPackageTableTab } from '../../types';
import { AppThunkAPIConfig } from '@/data/store/store';
import ErrorHandler from '@/data/network/errorHandler';
import socialPackageServices, { AllCommandProps } from '../../services';
import { SocialPackageTableFilterValues } from '@features/socialPackage/filterUtils';
import { SocialPackageTableTabsCounter } from '../utils';

export type SocialPackageTabsCounter = {
  [name in ESocialPackageTableTab]?: number;
};

const defaultSort = `${ESocialPackageTableColumn.LastStatusDate},desc`;

export interface SocialPackageListState extends Fetchable, Pageable<RzdSocialPackage> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly filter: SocialPackageTableFilterValues;
  readonly allSocialPackages: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: Nullable<ESocialPackageTableTab>;
  readonly tabsCounter: SocialPackageTabsCounter;
  readonly tabsCounterUnwatched: SocialPackageTabsCounter;
  readonly selected: RzdSocialPackage[];
}

type Reducer<T = undefined> = CaseReducer<SocialPackageListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SocialPackageListState> {
  socialPackageStateReset: Reducer;
  socialPackageDataReset: Reducer;
  socialPackageStartSession: Reducer<{ guid: UUID }>;
  socialPackageSetTab: Reducer<{ tab: ESocialPackageTableTab }>;
  socialPackageSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  socialPackageSetPage: Reducer<{ pageNumber: number }>;
  socialPackageNeedRefreshWatcherReset: Reducer;
  socialPackageNeedRefreshWatcherIncrement: Reducer;
  socialPackageSortReset: Reducer;
  socialPackageSetSort: Reducer<{ sort: string }>;
  socialPackageSelect: Reducer<RzdSocialPackage>;
  socialPackageUnselect: Reducer<RzdSocialPackage>;
  socialPackageAllSelect: Reducer;
  socialPackageAllUnselect: Reducer;
}

export type SocialPackageFetchProps = Omit<AllCommandProps, 'signal'>;
export type SocialPackageCountsFetchProps = SocialPackageFetchProps & {
  readonly tabs: ESocialPackageTableTab[];
};

export const socialPackageFetch = createAsyncThunk<
  Pageable<RzdSocialPackage>,
  SocialPackageFetchProps,
  AppThunkAPIConfig
>('socialPackage/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await socialPackageServices.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageCountFetch = createAsyncThunk<number, SocialPackageFetchProps, AppThunkAPIConfig>(
  'socialPackage/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await socialPackageServices.count({ ...props, signal });
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const socialPackageCountsFetch = createAsyncThunk<
  SocialPackageTableTabsCounter,
  SocialPackageCountsFetchProps,
  AppThunkAPIConfig
>('socialPackage/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await socialPackageServices.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      errors.map(e => {
        console.error(e);
      });
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageCountsUnwatchedFetch = createAsyncThunk<
  SocialPackageTableTabsCounter,
  SocialPackageCountsFetchProps,
  AppThunkAPIConfig
>('socialPackage/list/counts/unwatched/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await socialPackageServices.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

const initialState: SocialPackageListState = {
  guid: null,
  needRefreshWatcher: 0,
  pageNumber: 1,
  totalCount: 0,
  pageCount: 0,
  tab: null,
  tabsCounter: {},
  filter: {},
  tabsCounterUnwatched: {},
  allSocialPackages: {
    ...fetchableDefault,
    count: null,
    needRefreshWatcher: 0,
  },
  ...fetchableDefault,
  data: [],
  selected: [],
  search: {
    sort: defaultSort,
    pageSize: 10,
  },
};

const slice = createSlice<SocialPackageListState, Reducers>({
  name: 'list',
  initialState,
  reducers: {
    socialPackageStateReset: () => {
      return initialState;
    },
    socialPackageDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    socialPackageStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        return { ...initialState, guid };
      }
    },
    socialPackageSetTab: (state, { payload }) => {
      const { tab } = payload;
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
    },
    socialPackageSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    socialPackageSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    socialPackageNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    socialPackageNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    socialPackageSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    socialPackageSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    socialPackageSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    socialPackageSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }

      state.selected = [...selected, payload];
    },
    socialPackageUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    socialPackageAllSelect: state => {
      state.selected = [...state.data];
    },
    socialPackageAllUnselect: state => {
      state.selected = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(socialPackageFetch.pending, (state, { meta }) => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(socialPackageFetch.fulfilled, (state, { meta, payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(socialPackageFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(socialPackageCountFetch.pending, state => {
        state.allSocialPackages.isFetching = true;
        state.allSocialPackages.isFetched = false;
        state.allSocialPackages.isFailed = false;

        if (state.allSocialPackages.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allSocialPackages.count = null;
        }
      })
      .addCase(socialPackageCountFetch.fulfilled, (state, { payload }) => {
        state.allSocialPackages.isFetching = false;
        state.allSocialPackages.isFetched = true;
        state.allSocialPackages.isFailed = false;

        state.allSocialPackages.count = payload;
      })
      .addCase(socialPackageCountFetch.rejected, state => {
        state.allSocialPackages.isFetching = false;
        state.allSocialPackages.isFetched = false;
        state.allSocialPackages.isFailed = true;

        state.allSocialPackages.count = null;
      })
      .addCase(socialPackageCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(socialPackageCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      });
  },
});

export const {
  socialPackageStateReset,
  socialPackageDataReset,
  socialPackageStartSession,
  socialPackageSetTab,
  socialPackageSetPageSize,
  socialPackageSetPage,
  socialPackageSetFilter,
  socialPackageSortReset,
  socialPackageSetSort,
  socialPackageNeedRefreshWatcherReset,
  socialPackageNeedRefreshWatcherIncrement,
  socialPackageSelect,
  socialPackageUnselect,
  socialPackageAllUnselect,
  socialPackageAllSelect,
} = slice.actions;

export default slice.reducer;
