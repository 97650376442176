import { SocketContext } from '@mp-npm/mp-stomp-client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from '../../../domain/model/types';
import useCurrentUser from '../user/hooks/useCurrentUser';
import {
  ENotificationMessageType,
  ESocketHeaderType,
  NotificationMessageCallback,
  NotificationTypes,
  UseNotificationSocketProps,
} from './types';

const useNotificationSocket = ({ onMessageReceive }: UseNotificationSocketProps<NotificationTypes>): void => {
  const [guid] = useState<UUID>(uuidv4());
  const { userSpecific } = useCurrentUser();

  const topic = `/user/${userSpecific?.accountId ?? userSpecific?.id}/notification`;

  const { isConnected, onSubscribe, onUnsubscribe } = useContext(SocketContext);

  const onMessageReceiveInternal = useCallback<NotificationMessageCallback>(
    (msg, header) => {
      const type = header[ESocketHeaderType.Message] as ENotificationMessageType;
      onMessageReceive({ ...msg, type } as NotificationTypes, type);
    },
    [onMessageReceive]
  );

  useEffect(() => {
    if (isConnected) {
      onSubscribe(guid, topic, onMessageReceiveInternal);
      return () => {
        onUnsubscribe(guid, topic);
      };
    }
  }, [guid, topic, onMessageReceiveInternal, isConnected]);
};

export default useNotificationSocket;
