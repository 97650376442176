import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { SocialPackageReportContainer } from '../reports/socialPackage';

export const ReportDownloadsSocialPackagesAdapter = () => {
  const handlers = useReportDownloads();

  const { social_packages: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.SocialPackages);
  }, [handlers.onCloseDownloadDialog]);

  return open && <SocialPackageReportContainer onClose={onClose} />;
};
