import styled from '@emotion/styled';
import { viewConfig } from '@theme/viewConfig';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
`;

interface ContentWrapperProps {
  readonly fullscreen: boolean;
  readonly children: any;
}

export const ContentWrapper = styled(({ fullscreen, ...others }: ContentWrapperProps) => <div {...others} />)`
  width: calc(100% - ${viewConfig.menuPanelWidth} - 4rem);

  flex-grow: 1;
  position: relative;
`;
