import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { BookingOrdersFunnelContainer } from '@features/report/downloads/reports/bookingOrdersFunnel';

export const ReportDownloadsBookingOrdersFunnelAdapter = () => {
  const handlers = useReportDownloads();

  const { booking_orders_funnel: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.BookingOrdersFunnel);
  }, [handlers.onCloseDownloadDialog]);

  return (
    open && (
      <BookingOrdersFunnelContainer
        onClose={onClose}
        partnerId={open.partnerId}
      />
    )
  );
};
