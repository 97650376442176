import { AxiosResponse } from 'axios';
import moment from 'moment';
import { EDateTimeFormat } from '@/domain';
import { EReportSportPeriodType, ReportSportPeriodTypeItem } from './types';

export const reportSportPeriodTypes: ReportSportPeriodTypeItem[] = [
  { id: EReportSportPeriodType.DateEvent, name: 'Срок завершения мероприятия' },
  { id: EReportSportPeriodType.DateUserRegistration, name: 'Срок регистрации пользователей' },
];

export const defaultDatePeriod: Nullable<[Nullable<string>, Nullable<string>]> = [
  moment().add(-1, 'month').add(1, 'day').startOf('day').format(EDateTimeFormat.Server),
  moment().endOf('day').format(EDateTimeFormat.Server),
];

export const reportDownloadURL = (res: AxiosResponse<string>) => {
  const contentDisposition = res.headers['content-disposition'] || 'filename=file';
  const contentType = res.headers['content-type'] || '';

  const fileName: string = decodeURI(contentDisposition.split('filename=')[1]).replace(/["]+/g, '');
  const url = window.URL.createObjectURL(new Blob([res.data], { type: contentType }));
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const validateDatePeriod = (from: Nullable<any>, to: Nullable<any>): boolean => {
  if (!from || !to) {
    return false;
  }

  const fromDate = moment(from);
  const toDate = moment(to);

  return fromDate.isSameOrBefore(toDate);
};
