import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { Nullable, RzdSocialPackage, RzdSocialPackageCreateRequest, SportOption, UUID } from '@/domain';
import { ESocialPackageActionType } from '@features/socialPackage/types';
import { CaseReducer, createAsyncThunk, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import socialPackageServices from '../../services';

const handleActionProcess = (
  state: Draft<SocialPackageActionsState>,
  id: UUID,
  actionType: ESocialPackageActionType,
  fetchable: Fetchable,
  error: Nullable<ServerErrorResponse | undefined> = null
) => {
  let process = state.actions.find(change => change.id === id);

  if (process) {
    process.id = id;
    process.type = actionType;
    process.error = error ?? null;
    process.isFetching = fetchable.isFetching;
    process.isFetched = fetchable.isFetched;
    process.isFailed = fetchable.isFailed;
  } else {
    process = {
      ...fetchable,
      id,
      type: actionType,
      error: null,
    };

    state.actions.push(process);
  }
};

export const socialPackageActionsCreate = createAsyncThunk<
  RzdSocialPackage,
  RzdSocialPackageCreateRequest,
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/create', async (payload, { rejectWithValue }) => {
  try {
    return await socialPackageServices.create(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageActionsArchive = createAsyncThunk<
  void,
  { ids: UUID[]; reason: SportOption; comment?: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/archive', async (payload, { rejectWithValue }) => {
  try {
    await socialPackageServices.archive(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageActionsActivate = createAsyncThunk<
  void,
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/activate', async (payload, { rejectWithValue }) => {
  try {
    await socialPackageServices.activate(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageActionsBackToAppointed = createAsyncThunk<
  void,
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/backToAppointed', async (payload, { rejectWithValue }) => {
  try {
    await socialPackageServices.backToAppointed(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const socialPackageActionsAllocateLimit = createAsyncThunk<
  void,
  { ids: UUID[]; limitToSelfRest: number; limitToFamilyRest: number },
  AppThunkAPIConfig<ServerErrorResponse>
>('socialPackage/actions/allocateLimit', async (payload, { rejectWithValue }) => {
  try {
    await socialPackageServices.allocateLimit(payload);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export type SocialPackageActionDataType = RzdSocialPackage[];

type CreatectionDataDialogs = {
  name: 'create';
  data: Nullable<true>;
};

type CommandActionsDataDialogs = {
  name: Exclude<keyof SocialPackageActionsState['dialogs'], 'create'>;
  data: Nullable<SocialPackageActionDataType>;
};

export type SocialPackageActionsChangeDialogStateType = CommandActionsDataDialogs | CreatectionDataDialogs;

export interface SocialPackageActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: ESocialPackageActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly create: Nullable<true>;
    readonly allocateLimit: Nullable<SocialPackageActionDataType>;
    readonly archive: Nullable<SocialPackageActionDataType>;
    readonly activate: Nullable<SocialPackageActionDataType>;
    readonly backToAppointed: Nullable<SocialPackageActionDataType>;
  };
}

type Reducer<T = undefined> = CaseReducer<SocialPackageActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SocialPackageActionsState> {
  socialPackageActionsChangeDialogState: Reducer<SocialPackageActionsChangeDialogStateType>;
  socialPackageActionsOptimize: Reducer;
}

const slice = createSlice<SocialPackageActionsState, Reducers, 'socialPackage/actions'>({
  name: 'socialPackage/actions',
  initialState: {
    actions: [],
    create: fetchableDefault,
    dialogs: {
      create: null,
      archive: null,
      allocateLimit: null,
      activate: null,
      backToAppointed: null,
    },
  },
  reducers: {
    socialPackageActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      if (name === 'create') {
        state.dialogs.create = data;
      } else {
        state.dialogs[name] = data;
      }
    },
    socialPackageActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
      if (!state.create.isFetching) {
        state.create = fetchableDefault;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(socialPackageActionsCreate.pending, state => {
        state.create = fetchableFetching;
      })
      .addCase(socialPackageActionsCreate.fulfilled, state => {
        state.create = fetchableFetched;
      })
      .addCase(socialPackageActionsCreate.rejected, state => {
        state.create = fetchableFailed;
      })
      .addCase(socialPackageActionsArchive.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.Archive, fetchableFetching));
      })
      .addCase(socialPackageActionsArchive.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.Archive, fetchableFetched));
      })
      .addCase(socialPackageActionsArchive.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id =>
          handleActionProcess(state, id, ESocialPackageActionType.Archive, fetchableFailed, payload ?? null)
        );
      })
      .addCase(socialPackageActionsActivate.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.Activate, fetchableFetching));
      })
      .addCase(socialPackageActionsActivate.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.Activate, fetchableFetched));
      })
      .addCase(socialPackageActionsActivate.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id =>
          handleActionProcess(state, id, ESocialPackageActionType.Activate, fetchableFailed, payload ?? null)
        );
      })
      .addCase(socialPackageActionsBackToAppointed.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.BackToAppointed, fetchableFetching));
      })
      .addCase(socialPackageActionsBackToAppointed.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.BackToAppointed, fetchableFetched));
      })
      .addCase(socialPackageActionsBackToAppointed.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id =>
          handleActionProcess(state, id, ESocialPackageActionType.BackToAppointed, fetchableFailed, payload ?? null)
        );
      })
      .addCase(socialPackageActionsAllocateLimit.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.AllocateLimit, fetchableFetching));
      })
      .addCase(socialPackageActionsAllocateLimit.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, ESocialPackageActionType.AllocateLimit, fetchableFetched));
      })
      .addCase(socialPackageActionsAllocateLimit.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id =>
          handleActionProcess(state, id, ESocialPackageActionType.AllocateLimit, fetchableFailed, payload ?? null)
        );
      });
  },
});

export const { socialPackageActionsChangeDialogState, socialPackageActionsOptimize } = slice.actions;

export default slice.reducer;
