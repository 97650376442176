import { createAsyncThunk, createSelector, createSlice, Selector, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig, RootState } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { EUserRole, EUserStatus } from '../../../../../domain/model/enums';
import { Nullable } from '../../../../../domain/model/types';
import { AppUserSpecific, MpPartnerUserProfile, MpUserShort, SportUserProfile } from '../../../../../domain/model/user';
import { partnerDetailsOwnerByIdFetch } from '../../../partner/details/owner/store/slice';

export const userCurrentMpPartnerAdminSpecificFetch = createAsyncThunk<
  MpPartnerUserProfile,
  undefined,
  AppThunkAPIConfig
>('user/current/mpPartnerAdmin/fetch', async (props, { signal, rejectWithValue }) => {
  try {
    const { data: user } = await Api.user.mp.current({ signal });
    const { data: partners } = await Api.partner.all({ signal, ownerId: user.id, page: 1, pageSize: 100000 });

    if (partners.length > 1) {
      throw new Error('У пользователя более одного партнера!');
    }

    return {
      ...user,
      partner: partners?.[0] ?? null,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const userCurrentMpPartnerManagerSpecificFetch = createAsyncThunk<
  MpPartnerUserProfile,
  undefined,
  AppThunkAPIConfig
>('user/current/mpPartnerManager/fetch', async (props, { signal, rejectWithValue }) => {
  try {
    const { data: user } = await Api.user.mp.current({ signal });
    const { data: partners } = await Api.partner.all({ signal, managerId: user.id, page: 1, pageSize: 100000 });

    if (partners.length > 1) {
      throw new Error('У пользователя более одного партнера!');
    }

    return {
      ...user,
      partner: partners[0],
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const userCurrentMpCommonUserSpecificFetch = createAsyncThunk<MpUserShort, undefined, AppThunkAPIConfig>(
  'user/current/mpUser/fetch',
  async (props, { signal, rejectWithValue }) => {
    try {
      const { data: user } = await Api.user.mp.current({ signal });
      const { data: partners } = await Api.partner.all({ signal, managerId: user.id, page: 1, pageSize: 100000 });

      return {
        ...user,
        partner: partners[0],
      };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const userCurrentMpAdminSpecificFetch = createAsyncThunk<MpUserShort, undefined, AppThunkAPIConfig>(
  'user/current/mpAdmin/fetch',
  async (props, { signal, rejectWithValue }) => {
    try {
      const { data: user } = await Api.user.mp.current({ signal });
      return user;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const userCurrentMpCustomerSpecificFetch = createAsyncThunk<MpUserShort, undefined, AppThunkAPIConfig>(
  'user/current/mpCustomer/fetch',
  async (props, { signal, rejectWithValue }) => {
    try {
      const { data: user } = await Api.user.mp.current({ signal });
      return user;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const userCurrentSportSpecificFetch = createAsyncThunk<
  SportUserProfile,
  { roles: EUserRole[] },
  AppThunkAPIConfig
>('user/current/sport/fetch', async ({ roles }, { signal, dispatch, rejectWithValue }) => {
  try {
    const { data: user } = await Api.user.sport.current({ signal });
    return {
      ...user,
      roles,
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const userSettingsChange = createAsyncThunk<void, { key: string; value: string }, AppThunkAPIConfig>(
  'user/current/settings/change',
  async ({ key, value }, { rejectWithValue }) => {
    try {
      await Api.user.settings.setValue({ key, value });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const userSettingsFetch = createAsyncThunk<
  Nullable<any>,
  { key: string; defaultValue?: Nullable<any> },
  AppThunkAPIConfig
>('user/current/settings/fetch', async ({ key }, { rejectWithValue }) => {
  try {
    const { data } = await Api.user.settings.getValue({ key });
    return data;
  } catch (e: any) {
    if (e.response.status !== 404) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
});

interface UserCurrentCommonSettingsState {
  readonly [key: string]: string;
}

export interface UserCurrentCommonState {
  readonly userSpecific: Fetchable & {
    readonly data: Nullable<AppUserSpecific>;
  };
  readonly userSettings: UserCurrentCommonSettingsState;
}

/*type Reducer<T = undefined> = CaseReducer<UserCurrentCommonState, PayloadAction<T>>*/

interface Reducers extends SliceCaseReducers<UserCurrentCommonState> {}

const slice = createSlice<UserCurrentCommonState, Reducers, 'user'>({
  name: 'user',
  initialState: {
    userSpecific: {
      ...fetchableDefault,
      data: null,
    },
    userSettings: {},
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(userCurrentMpPartnerAdminSpecificFetch.pending, state => {
        state.userSpecific.isFetching = true;
        state.userSpecific.isFetched = false;
        state.userSpecific.isFailed = false;
      })
      .addCase(userCurrentMpPartnerAdminSpecificFetch.fulfilled, (state, { payload }) => {
        state.userSpecific.isFetching = false;
        state.userSpecific.isFetched = true;
        state.userSpecific.isFailed = false;
        state.userSpecific.data = {
          id: payload.id,
          accountId: payload.accountId,
          photo: payload.photo ?? null,

          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          status: payload.status,
          locality: payload.locality,
          birthDate: payload.birthDate,
          gender: payload.gender,

          sport: null,
          mpAdmin: null,
          mpPartner: payload,
        };
      })
      .addCase(userCurrentMpPartnerAdminSpecificFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.userSpecific.isFetching = false;
          state.userSpecific.isFetched = false;
          state.userSpecific.isFailed = true;
        }
      })
      .addCase(userCurrentMpPartnerManagerSpecificFetch.pending, state => {
        state.userSpecific.isFetching = true;
        state.userSpecific.isFetched = false;
        state.userSpecific.isFailed = false;
      })
      .addCase(userCurrentMpPartnerManagerSpecificFetch.fulfilled, (state, { payload }) => {
        state.userSpecific.isFetching = false;
        state.userSpecific.isFetched = true;
        state.userSpecific.isFailed = false;
        state.userSpecific.data = {
          id: payload.id,
          accountId: payload.accountId,
          photo: payload.photo ?? null,

          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          status: payload.status,
          locality: payload.locality,
          birthDate: payload.birthDate,
          gender: payload.gender,

          sport: null,
          mpAdmin: null,
          mpPartner: payload,
        };
      })
      .addCase(userCurrentMpPartnerManagerSpecificFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.userSpecific.isFetching = false;
          state.userSpecific.isFetched = false;
          state.userSpecific.isFailed = true;
        }
      })
      .addCase(userCurrentMpAdminSpecificFetch.pending, state => {
        state.userSpecific.isFetching = true;
        state.userSpecific.isFetched = false;
        state.userSpecific.isFailed = false;
      })
      .addCase(userCurrentMpAdminSpecificFetch.fulfilled, (state, { payload }) => {
        state.userSpecific.isFetching = false;
        state.userSpecific.isFetched = true;
        state.userSpecific.isFailed = false;
        state.userSpecific.data = {
          id: payload.id,
          accountId: payload.accountId,
          photo: payload.photo ?? null,

          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          status: payload.status,
          locality: payload.locality,
          birthDate: payload.birthDate,
          gender: payload.gender,

          sport: null,
          mpAdmin: payload,
          mpPartner: null,
        };
      })
      .addCase(userCurrentMpAdminSpecificFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.userSpecific.isFetching = false;
          state.userSpecific.isFetched = false;
          state.userSpecific.isFailed = true;
        }
      })
      .addCase(userCurrentSportSpecificFetch.pending, state => {
        state.userSpecific.isFetching = true;
        state.userSpecific.isFetched = false;
        state.userSpecific.isFailed = false;
      })
      .addCase(userCurrentSportSpecificFetch.fulfilled, (state, { payload }) => {
        state.userSpecific.isFetching = false;
        state.userSpecific.isFetched = true;
        state.userSpecific.isFailed = false;
        state.userSpecific.data = {
          id: payload.id,
          accountId: null,
          photo: payload.photo ?? null,

          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.midName,
          email: payload.email,
          phone: payload.phone,
          status: EUserStatus.Enabled,
          locality: payload.locality,
          birthDate: payload.birthDate,
          gender: payload.gender,

          sport: payload,
          mpAdmin: null,
          mpPartner: null,
        };
      })
      .addCase(userCurrentSportSpecificFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.userSpecific.isFetching = false;
          state.userSpecific.isFetched = false;
          state.userSpecific.isFailed = true;
        }
      })
      .addCase(userCurrentMpCustomerSpecificFetch.pending, state => {
        state.userSpecific.isFetching = true;
        state.userSpecific.isFetched = false;
        state.userSpecific.isFailed = false;
      })
      .addCase(userCurrentMpCustomerSpecificFetch.fulfilled, (state, { payload }) => {
        state.userSpecific.isFetching = false;
        state.userSpecific.isFetched = true;
        state.userSpecific.isFailed = false;
        state.userSpecific.data = {
          id: payload.id,
          accountId: payload.accountId,
          photo: payload.photo ?? null,

          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          status: payload.status,
          locality: payload.locality,
          birthDate: payload.birthDate,
          gender: payload.gender,

          sport: null,
          mpAdmin: null,
          mpPartner: null,
        };
      })
      .addCase(userCurrentMpCustomerSpecificFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.userSpecific.isFetching = false;
          state.userSpecific.isFetched = false;
          state.userSpecific.isFailed = true;
        }
      })
      .addCase(userCurrentMpCommonUserSpecificFetch.pending, state => {
        state.userSpecific.isFetching = true;
        state.userSpecific.isFetched = false;
        state.userSpecific.isFailed = false;
      })
      .addCase(userCurrentMpCommonUserSpecificFetch.fulfilled, (state, { payload }) => {
        state.userSpecific.isFetching = false;
        state.userSpecific.isFetched = true;
        state.userSpecific.isFailed = false;
        state.userSpecific.data = {
          id: payload.id,
          accountId: payload.accountId,
          photo: payload.photo ?? null,

          firstName: payload.firstName,
          lastName: payload.lastName,
          middleName: payload.middleName,
          email: payload.email,
          phone: payload.phone,
          status: payload.status,
          locality: payload.locality,
          birthDate: payload.birthDate,
          gender: payload.gender,

          sport: null,
          mpAdmin: null,
          mpPartner: null,
        };
      })
      .addCase(userCurrentMpCommonUserSpecificFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.userSpecific.isFetching = false;
          state.userSpecific.isFetched = false;
          state.userSpecific.isFailed = true;
        }
      })
      .addCase(partnerDetailsOwnerByIdFetch.fulfilled, (state, { payload }) => {
        const { partner } = payload;
        if (state.userSpecific?.data?.mpPartner) {
          state.userSpecific.data.mpPartner.partner = partner;
        }
      })
      .addCase(userSettingsChange.pending, (state, { meta }) => {
        const { key, value } = meta.arg;
        state.userSettings[key] = value;
      })
      .addCase(userSettingsFetch.pending, (state, { meta }) => {
        const { key, defaultValue } = meta.arg;
        if (defaultValue) {
          state.userSettings[key] = defaultValue;
        }
      })
      .addCase(userSettingsFetch.fulfilled, (state, { meta, payload }) => {
        const { key } = meta.arg;
        if (payload) {
          state.userSettings[key] = payload;
        }
      });
  },
});

export default slice.reducer;

const settingsSelector: Selector<RootState, Nullable<UserCurrentCommonSettingsState>> = state =>
  state.user.current.common.userSettings;
const settingsKeySelector: Selector<RootState, string, [string]> = (state, key) => key;

export const createUserSettingsByKeySelector = () =>
  createSelector(settingsSelector, settingsKeySelector, (settings, key): Nullable<string> => settings?.[key] ?? null);
