import { EUserRole, MPReport } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import { EReportsTableColumn, EReportType } from '@features/report/types';
import { v4 as uuidv4 } from 'uuid';

const allReports: Record<EReportType, MPReport> = {
  [EReportType.OffersStatistic]: {
    id: uuidv4(),
    name: 'Конверсия предложений',
    type: EReportType.OffersStatistic,
    description: 'Статистика по просмотрам и показам ТП и КП, подсчет конверсии и др.',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
  },
  [EReportType.Offers]: {
    id: uuidv4(),
    name: 'Предложения',
    type: EReportType.Offers,
    description: 'Статистика по количеству КП и ТП, активациям и переходам на сайт партнера',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
  },
  [EReportType.Users]: {
    id: uuidv4(),
    name: 'Пользователи',
    type: EReportType.Users,
    description: 'Статистика по количеству зарегистрированных пользователей',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp],
  },
  [EReportType.Partners]: {
    id: uuidv4(),
    name: 'Партнёры',
    type: EReportType.Partners,
    description: 'Статистика по количеству и статусам партнеров',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp],
  },
  [EReportType.SocialPackages]: {
    id: uuidv4(),
    name: 'Назначение и активация КСП',
    type: EReportType.SocialPackages,
    description: 'Статистика по количеству назначенных и активированных льгот КСП, наличию заказов ДиО',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp],
  },
  [EReportType.BookingOrdersFunnel]: {
    id: uuidv4(),
    name: 'Воронка заказов объектов ДиО',
    type: EReportType.BookingOrdersFunnel,
    description: 'Воронка заказов на объектах отдыха в разрезе статусов по всем объектам ДиО',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
  },
  [EReportType.BookingOrdersServices]: {
    id: uuidv4(),
    name: 'Заказы услуг объектов ДиО',
    type: EReportType.BookingOrdersServices,
    description: 'Детализация по заказам на услуги объектов ДиО',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
  },
  [EReportType.BookingOrdersItems]: {
    id: uuidv4(),
    name: 'Состав заказов объектов ДиО',
    type: EReportType.BookingOrdersItems,
    description: 'Детализация по составу заказов на услуги объектов ДиО',
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
  },
  [EReportType.BookingOrdersStatistic]: {
    id: uuidv4(),
    name: 'Объекты Досуга и отдыха',
    type: EReportType.BookingOrdersStatistic,
    description: 'Статистика по реализации услуг на объектах Досуга и Отдыха',
    disabled: true,
    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
  },
  [EReportType.Events]: {
    id: uuidv4(),
    name: 'Мероприятия, сводный',
    type: EReportType.Events,
    description: 'Сводная статистика по мероприятиям и участникам',
    roles: [EUserRole.SystemAdmin, EUserRole.EventAdmin],
  },
  [EReportType.EventsDetailed]: {
    id: uuidv4(),
    name: 'Мероприятия, детальный',
    type: EReportType.EventsDetailed,
    description: 'Детальная статистика по мероприятиям и участникам',
    roles: [EUserRole.SystemAdmin, EUserRole.EventAdmin],
  },
  [EReportType.SportUsers]: {
    id: uuidv4(),
    name: 'Пользователи',
    type: EReportType.SportUsers,
    description: 'Статистика по количеству зарегистрированных пользователей и их участию в мероприятиях',
    roles: [EUserRole.SystemAdmin],
  },
};

export const getReportsByUserRole = (roles: EUserRole[]): MPReport[] => {
  return Object.values(allReports).filter(report =>
    report.roles.some(role => roles.includes(role) && !report.disabled)
  );
};

export const reportsTableColumns: DataTableColumns<EReportsTableColumn> = {
  [EReportsTableColumn.Name]: {
    title: 'Название отчета',
    wrap: true,
  },
  [EReportsTableColumn.Description]: {
    title: 'Описание',
    wrap: true,
  },
};
