import { Field, FieldsGroup } from '@components/fields';
import { Wrapper } from './controls';
import { RzdSocialPackage } from '@/domain';
import { formatCostWithDecimal } from '@/presentation/utils';

interface BookingOrderCSPInfoProps {
  readonly socialPackage: RzdSocialPackage;
}

const BookingOrderCSPInfo = (props: BookingOrderCSPInfoProps) => {
  const { socialPackage } = props;

  return (
    <Wrapper>
      <FieldsGroup>
        <Field label={'Общий лимит компенсации на оздоровление'}>{formatCostWithDecimal(socialPackage.maxLimit)}</Field>
        <Field label={'Для себя'}>{formatCostWithDecimal(socialPackage.limitToSelfRest)}</Field>
        <Field label={'Для членов семьи'}>{formatCostWithDecimal(socialPackage.limitToFamilyRest)}</Field>
      </FieldsGroup>
    </Wrapper>
  );
};

export default BookingOrderCSPInfo;
