import { ERzdSocialPackageEmployeeAgreement, ESocialPackageStatus, RzdSocialPackage } from '@/domain';
import { DataTableColumns } from '@components/common/table';
import {
  ESocialPackageTableColumn,
  ESocialPackageTableTab,
  SocialPackageActionTableType,
} from '@features/socialPackage/types';

export type SocialPackageActionCall = {
  readonly action: SocialPackageActionTableType;
  readonly socialPackage: RzdSocialPackage;
};

export const socialPackageTableColumns: DataTableColumns<ESocialPackageTableColumn> = {
  [ESocialPackageTableColumn.LastStatusDate]: {
    title: 'Дата обновления',
    wrap: true,
    width: '9rem',
    sortable: true,
  },
  [ESocialPackageTableColumn.EmployeeFullName]: {
    title: 'ФИО',
    wrap: true,
    width: '15rem',
  },
  [ESocialPackageTableColumn.ValidityYear]: {
    title: 'Год назначения',
    wrap: false,
    sortable: true,
    width: '8rem',
  },
  [ESocialPackageTableColumn.EmployeeCategory]: {
    title: 'Код значимой категории',
    wrap: true,
    sortable: true,
    width: '12rem',
  },
  [ESocialPackageTableColumn.EndDate]: {
    title: 'Дата окончания',
    sortable: true,
    wrap: false,
    width: '10rem',
  },
  [ESocialPackageTableColumn.EmployeeAgreement]: {
    title: 'Согласие получено',
    sortable: true,
    wrap: false,
    width: '10rem',
  },
  [ESocialPackageTableColumn.MaxLimit]: {
    title: 'Общий лимит',
    sortable: true,
    wrap: false,
    width: '10rem',
  },
  [ESocialPackageTableColumn.LimitToSelfRest]: {
    title: 'Для себя',
    sortable: true,
    wrap: false,
    width: '10rem',
  },
  [ESocialPackageTableColumn.LimitToFamilyRest]: {
    title: 'Для членов семьи',
    sortable: true,
    wrap: false,
    width: '10rem',
  },
  [ESocialPackageTableColumn.RZDRoad]: {
    title: 'Железная дорога',
    sortable: true,
    wrap: false,
    width: '15rem',
  },
  [ESocialPackageTableColumn.Status]: {
    title: 'Статус',
    sortable: true,
    wrap: false,
    width: '10rem',
  },
  [ESocialPackageTableColumn.RejectionReason]: {
    title: 'Причина перевода в архив',
    sortable: false,
    wrap: true,
    width: '13rem',
  },
};

const socialPackageDefaultColumns = [
  ESocialPackageTableColumn.LastStatusDate,
  ESocialPackageTableColumn.EmployeeFullName,
  ESocialPackageTableColumn.EmployeeCategory,
  ESocialPackageTableColumn.RZDRoad,
  ESocialPackageTableColumn.ValidityYear,
  ESocialPackageTableColumn.EndDate,
  ESocialPackageTableColumn.MaxLimit,
  ESocialPackageTableColumn.LimitToSelfRest,
  ESocialPackageTableColumn.LimitToFamilyRest,
  ESocialPackageTableColumn.EmployeeAgreement,
];

export type SocialPackageTableTabsCounter = {
  [name in ESocialPackageTableTab]?: number;
};

export type SocialPackageCounterByStatus = {
  [name in ESocialPackageStatus]?: number;
};

export const getSocialPackageTableTabName = (tab: ESocialPackageTableTab): string => {
  switch (tab) {
    case ESocialPackageTableTab.Appointed:
      return 'Распределение лимитов';
    case ESocialPackageTableTab.Active:
      return 'Действующие';
    case ESocialPackageTableTab.Allocated:
      return 'Готовы к активации';
    case ESocialPackageTableTab.Archive:
      return 'Архив';
  }
};

export const employeeAgreementLabel = (state: Nullable<ERzdSocialPackageEmployeeAgreement>) => {
  switch (state) {
    case ERzdSocialPackageEmployeeAgreement.Agreed:
      return 'Да';
    case ERzdSocialPackageEmployeeAgreement.Refused:
      return 'Нет';
    case null:
      return 'Нет';
  }
};

const getSocialPackageDefaultColumns = (): ESocialPackageTableColumn[] => {
  return socialPackageDefaultColumns;
};

export const getSocialPackageTableColumns = (
  tab: ESocialPackageTableTab
): DataTableColumns<ESocialPackageTableColumn> => {
  const defaultColumns = getSocialPackageDefaultColumns();
  const allColumns: ESocialPackageTableColumn[] = [];

  switch (tab) {
    case ESocialPackageTableTab.Active:
      allColumns.push(
        ESocialPackageTableColumn.LastStatusDate,
        ESocialPackageTableColumn.Status,
        ESocialPackageTableColumn.EmployeeFullName,
        ESocialPackageTableColumn.EmployeeCategory,
        ESocialPackageTableColumn.RZDRoad,
        ESocialPackageTableColumn.ValidityYear,
        ESocialPackageTableColumn.EndDate,
        ESocialPackageTableColumn.MaxLimit,
        ESocialPackageTableColumn.LimitToSelfRest,
        ESocialPackageTableColumn.LimitToFamilyRest,
        ESocialPackageTableColumn.EmployeeAgreement
      );
      break;
    case ESocialPackageTableTab.Archive:
      allColumns.push(
        ESocialPackageTableColumn.LastStatusDate,
        ESocialPackageTableColumn.RejectionReason,
        ESocialPackageTableColumn.EmployeeFullName,
        ESocialPackageTableColumn.EmployeeCategory,
        ESocialPackageTableColumn.RZDRoad,
        ESocialPackageTableColumn.ValidityYear,
        ESocialPackageTableColumn.EndDate,
        ESocialPackageTableColumn.MaxLimit,
        ESocialPackageTableColumn.LimitToSelfRest,
        ESocialPackageTableColumn.LimitToFamilyRest
      );
      break;
    default:
      allColumns.push(
        ESocialPackageTableColumn.LastStatusDate,
        ESocialPackageTableColumn.EmployeeFullName,
        ESocialPackageTableColumn.EmployeeCategory,
        ESocialPackageTableColumn.RZDRoad,
        ESocialPackageTableColumn.ValidityYear,
        ESocialPackageTableColumn.EndDate,
        ESocialPackageTableColumn.MaxLimit,
        ESocialPackageTableColumn.LimitToSelfRest,
        ESocialPackageTableColumn.LimitToFamilyRest,
        ESocialPackageTableColumn.EmployeeAgreement
      );
  }

  return allColumns.reduce<DataTableColumns<ESocialPackageTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...socialPackageTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getStatesFilterForSocialPackageTableTab = (tab: ESocialPackageTableTab): ESocialPackageStatus[] => {
  switch (tab) {
    case ESocialPackageTableTab.Appointed:
      return [ESocialPackageStatus.Appointed];
    case ESocialPackageTableTab.Allocated:
      return [ESocialPackageStatus.Appointed];
    case ESocialPackageTableTab.Active:
      return [ESocialPackageStatus.Ready, ESocialPackageStatus.Upcoming, ESocialPackageStatus.Active];
    case ESocialPackageTableTab.Archive:
      return [ESocialPackageStatus.Archived];
  }
};

export const getRestLimitsExist = (t: ESocialPackageTableTab) => {
  switch (t) {
    case ESocialPackageTableTab.Appointed:
      return false;
    case ESocialPackageTableTab.Allocated:
      return true;
    case ESocialPackageTableTab.Archive:
    case ESocialPackageTableTab.Active:
      return;
  }
};
