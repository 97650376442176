import { AppUserSpecific, EPaymentType, MpUserData, Nullable, SportUserProfile, SportUserProfileShort } from '@/domain';
import { locale } from '@/locale';
import { AppConfigurator } from '@/system/appConfigurator';

export enum EUserNameRule {
  Normal,
  Reverse,
}

interface GetUserFullNameProps {
  readonly user: MpUserData | AppUserSpecific | SportUserProfileShort;
  readonly separator?: string;
  readonly rule?: EUserNameRule;
}

export const getUserFullName = ({ user, separator = ' ', rule = EUserNameRule.Normal }: GetUserFullNameProps) => {
  const userFullName =
    rule === EUserNameRule.Normal
      ? [user.lastName, user.firstName, user.middleName]
      : [user.firstName, user.middleName, user.lastName];
  return userFullName.filter(item => !!item).join(separator) || 'не определено';
};

export const getUserShortName = (
  user: MpUserData | AppUserSpecific | SportUserProfile | SportUserProfileShort,
  rule = EUserNameRule.Normal,
  separator = ' '
) => {
  const userFullName =
    rule === EUserNameRule.Normal ? [user.lastName, user.firstName] : [user.firstName, user.lastName];
  return userFullName.filter(item => !!item).join(separator) || 'не определено';
};

export const formatNumber = (num: Nullable<number>): Nullable<string> => {
  if (num === null || num === undefined) return null;

  return num.toLocaleString(locale.full).replace(',', '.');
};

export const formatCost = (cost: Nullable<number>): Nullable<string> => {
  const formatted = formatNumber(cost);
  if (formatted === null) return null;

  return `${formatted} ${locale.currency}`;
};

export const formatNumberWithDecimal = (num: Nullable<number>, decimalPlaces: number = 0): Nullable<string> => {
  if (num === null || num === undefined) return null;

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: Number.isInteger(num) ? 0 : decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };

  return num.toLocaleString(locale.full, options).replace(',', '.');
};

export const formatCostWithDecimal = (cost: Nullable<number>, decimalPlaces: number = 2): Nullable<string> => {
  const formatted = formatNumberWithDecimal(cost, decimalPlaces);
  if (formatted === null) return null;

  return `${formatted} ${locale.currency}`;
};

export const formatCostBenefit = (costBenefit: Nullable<number>): Nullable<string> => {
  const formatted = formatNumber(costBenefit);
  if (formatted === null) return null;

  return `${formatted} ${locale.currencyBenefit}`;
};

export const formatCostAsp = (costAsp: Nullable<number>): Nullable<string> => {
  const formatted = formatNumber(costAsp);
  if (formatted === null) return null;

  return `${formatted} ${locale.currencyAsp}`;
};

export const formatCostAspRub = (costAspRub: Nullable<number>): Nullable<string> => {
  const formatted = formatNumber(costAspRub);
  if (formatted === null) return null;

  return `${formatted} ${locale.currencyAspRub}`;
};

export const formatCurrency = (currency: Nullable<number>, type: EPaymentType) => {
  switch (type) {
    case EPaymentType.BenefitRzdPoint:
      return formatCostBenefit(currency);
    case EPaymentType.Rub:
      return formatCost(currency);
    case EPaymentType.AspRzdRub:
      return formatCostAsp(currency);
    case EPaymentType.Free:
      return null;
  }
};

export const formatNumberRange = (
  from: Nullable<number>,
  to: Nullable<number>,
  formatter: (value: Nullable<number>) => Nullable<string> = formatNumber
): Nullable<string> => {
  if (from === null && to === null) return null;

  if (from === to) return formatter(from);

  const range = [];
  range.push(from === null ? null : 'от ' + formatter(from));
  range.push(to === null ? to : 'до ' + formatter(to));

  return range.filter(value => value !== null).join(' ');
};

export const formatDateRange = (
  from: Nullable<string>,
  to: Nullable<string>,
  formatter: (value: Nullable<string>) => Nullable<string>
): Nullable<string> => {
  if (from === null && to === null) return null;

  const range = [];
  range.push(from === null ? null : 'с ' + formatter(from));
  range.push(to === null ? to : 'по ' + formatter(to));

  return range.filter(value => value !== null).join(' ');
};

export const convertStringToArray = (str: string, separator = ','): string[] =>
  str.trim().length > 0 ? str.trim().split(separator) : [];

export const getPrivilegeTestCmsPath = (): string => AppConfigurator.getInstance().getPrivilegeTestCms();

export const normalizedLink = (link: string) => (link.indexOf('http') === -1 ? `http://${link}` : link);

export const getMapsConfig = () => {
  const appConfig = AppConfigurator.getInstance();
  return appConfig.getMapsConfig();
};
