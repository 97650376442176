export interface ReportSportPeriodTypeItem {
  readonly id: EReportSportPeriodType;
  readonly name: string;
}

export enum EReportType {
  OffersStatistic = 'offer_statistic',
  Offers = 'offer',
  Users = 'users',
  Partners = 'partners',
  BookingOrdersStatistic = 'booking_orders_statistic',
  SportUsers = 'sport_users',
  Events = 'events',
  EventsDetailed = 'events_detailed',
  /** Отчет для получения данных о назначении и активации КСП. */
  SocialPackages = 'social_packages',
  /** Воронка заказов на услуги объектов ДиО */
  BookingOrdersFunnel = 'booking_orders_funnel',
  /**  Отчет для получения детальных данных о заказах на услуги объектов ДиО */
  BookingOrdersServices = 'booking_order_services',
  /** Отчет для получения детальных данных о составе заказов на услуги объектов ДиО. Каждая строка - добавленная в состав заказа позиция в любом статусе. */
  BookingOrdersItems = 'booking_order_items',
}

export enum EReportSportPeriodType {
  DateEvent = 'dateEvent',
  DateUserRegistration = 'dateUserRegistration',
}

export enum EReportsTableColumn {
  Name = 'name',
  Description = 'description',
}
