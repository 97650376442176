import { BannerPlace, BannerRequest, EBannerPartition, Nullable, ObjectTarget, Target } from '@/domain';
import Splitter from '../../../components/common/splitter';
import { OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import BannerPreview from '../components/preview';
import BannerPreviewWrapper from '../components/preview/wrapper';
import {
  ColumnWrapper,
  Content,
  DesktopPreviewWrapper,
  MobilePreviewWrapper,
  PreviewWrapper,
  StepWrapper,
  Wrapper,
} from './controls';
import BannerEditStepGeneral from './steps/general';

interface BannerEditProps {
  readonly partition: EBannerPartition;
  readonly banner: BannerRequest;
  readonly validation: Nullable<ValidationResult<BannerRequest>>;
  readonly validationTarget: Nullable<ValidationResult<Target>>;
  readonly places: BannerPlace[];
  readonly targets: ObjectTarget[];
  readonly desktopImageDescription: string;
  readonly mobileImageDescription: string;
  readonly onFilterDesktopImage: (file: File) => Promise<Nullable<File>>;
  readonly onFilterMobileImage: (file: File) => Promise<Nullable<File>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<BannerRequest>;
  readonly onChangeTargetAttribute: OnChangeObjectAttribute<Target>;
}

const BannerEdit = (props: BannerEditProps) => {
  const {
    partition,
    banner,
    validation,
    validationTarget,
    places,
    targets,
    desktopImageDescription,
    mobileImageDescription,
    onFilterDesktopImage,
    onFilterMobileImage,
    onChangeAttribute,
    onChangeTargetAttribute,
  } = props;

  return (
    <Wrapper>
      <StepWrapper>
        <ColumnWrapper>
          <Content>
            <BannerEditStepGeneral
              partition={partition}
              banner={banner}
              validation={validation}
              validationTarget={validationTarget}
              places={places}
              targets={targets}
              desktopImageDescription={desktopImageDescription}
              mobileImageDescription={mobileImageDescription}
              onFilterDesktopImage={onFilterDesktopImage}
              onFilterMobileImage={onFilterMobileImage}
              onChangeAttribute={onChangeAttribute}
              onChangeTargetAttribute={onChangeTargetAttribute}
            />
          </Content>
        </ColumnWrapper>
        <ColumnWrapper>
          <PreviewWrapper>
            <DesktopPreviewWrapper>
              <BannerPreviewWrapper label='Предпросмотр десктопного формата'>
                <BannerPreview src={banner.desktopFormatImage?.path ?? null} />
              </BannerPreviewWrapper>
            </DesktopPreviewWrapper>
            <Splitter
              variant='horizontal'
              size={3}
            />
            <MobilePreviewWrapper>
              <BannerPreviewWrapper label='Предпросмотр мобильного формата'>
                <BannerPreview src={banner.mobileFormatImage?.path ?? null} />
              </BannerPreviewWrapper>
            </MobilePreviewWrapper>
          </PreviewWrapper>
        </ColumnWrapper>
      </StepWrapper>
    </Wrapper>
  );
};

export default BannerEdit;
