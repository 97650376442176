import Api from '@/data/api';
import {
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
  pageSizeAll,
} from '@/domain';
import { EReportOffersStatisticByPartnerFilterItem } from '@features/report/downloads/reports/offersStatistic';

export enum EBookingOrdersServicesFunnelFilterItem {
  DatePeriod = 'datePeriod',
  Partner = 'partner',
}

export type ReportBookingOrdersServicesFilterEditStrategy =
  DataFilterStrategyBase<EBookingOrdersServicesFunnelFilterItem>;

export type ReportBookingOrdersServicesFilterValues = {
  readonly [EBookingOrdersServicesFunnelFilterItem.DatePeriod]?: DataFilterValueItem<
    Nullable<[Nullable<string>, Nullable<string>]>
  >;
  readonly [EReportOffersStatisticByPartnerFilterItem.Partner]?: DataFilterValueItem<
    Nullable<DataFilterStrategyListItem>
  >;
};

export const getReportBookingOrdersServicesFunnelFilterStrategies = (
  values: ReportBookingOrdersServicesFilterValues,
  partnerId: Nullable<UUID>
): ReportBookingOrdersServicesFilterEditStrategy[] => {
  const strategies: ReportBookingOrdersServicesFilterEditStrategy[] = [];
  strategies.push({
    type: EDataFilterType.DatePeriod,
    key: EBookingOrdersServicesFunnelFilterItem.DatePeriod,
    label: '',
    labelPeriod: {
      from: 'Период с',
      to: 'по',
    },
    buttonsPeriod: true,
    preview: null,
    value: values[EBookingOrdersServicesFunnelFilterItem.DatePeriod]?.value || null,
  });

  if (!partnerId) {
    strategies.push({
      type: EDataFilterType.AutocompleteSingle,
      key: EBookingOrdersServicesFunnelFilterItem.Partner,
      label: 'Партнёр',
      preview: `Партнер (${values[EBookingOrdersServicesFunnelFilterItem.Partner]?.value?.name})` || null,
      value: values[EBookingOrdersServicesFunnelFilterItem.Partner]?.value || null,
      source: (name: string) =>
        name
          ? Api.partner
              .all({
                query: name,
                sort: 'name,asc',
                page: 1,
                pageSize: pageSizeAll,
              })
              .then(response => response.data)
          : Promise.resolve([]),
    });
  }

  return strategies;
};
