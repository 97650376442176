import { ReportDownloadsOfferAdapter } from './offers';
import { ReportDownloadsOffersStatisticAdapter } from './offersStatistic';
import { ReportDownloadsPartnersAdapter } from './partners';
import { ReportDownloadsBookingOrdersStatisticAdapter } from './bookingOrdersStatistic';
import { ReportDownloadsUsersAdapter } from './users';
import { ReportDownloadsEventsDetailedAdapter } from './eventsDetailed';
import { ReportDownloadsSportUsersAdapter } from './sportUsers';
import { ReportDownloadsEventsAdapter } from './events';
import { ReportDownloadsSocialPackagesAdapter } from './socialPackages';
import { ReportDownloadsBookingOrdersFunnelAdapter } from './bookingOrdersFunnel';
import { ReportDownloadsBookingOrdersServicesAdapter } from '@features/report/downloads/adapters/bookingOrdersServices';
import { ReportDownloadsBookingOrdersItemsAdapter } from '@features/report/downloads/adapters/bookingOrdersItems';

const ReportDownloadsDialogsAdapter = () => {
  return (
    <>
      <ReportDownloadsOfferAdapter />
      <ReportDownloadsOffersStatisticAdapter />
      <ReportDownloadsPartnersAdapter />
      <ReportDownloadsBookingOrdersStatisticAdapter />
      <ReportDownloadsUsersAdapter />
      <ReportDownloadsEventsAdapter />
      <ReportDownloadsEventsDetailedAdapter />
      <ReportDownloadsSportUsersAdapter />
      <ReportDownloadsSocialPackagesAdapter />
      <ReportDownloadsBookingOrdersFunnelAdapter />
      <ReportDownloadsBookingOrdersServicesAdapter />
      <ReportDownloadsBookingOrdersItemsAdapter />
    </>
  );
};

export default ReportDownloadsDialogsAdapter;
