import { EMPUserGroup, EOfferType, Nullable, UUID } from '@/domain';
import axios, { AxiosResponse } from 'axios';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getComServicesEndpoint, getSportBaseEndpoint } from './utils';

type WithPeriodProps = {
  readonly startDate?: Nullable<string>;
  readonly endDate?: Nullable<string>;
};

type MpOffersProps = ApiCancellable &
  WithPeriodProps & {
    readonly offerType?: Nullable<EOfferType | EOfferType[]>;
    readonly partnerId?: Nullable<UUID>;
  };

type MpOffersStatisticProps = MpOffersProps & {
  readonly reportUserGroup?: Nullable<EMPUserGroup>;
};

type MpPartnersProps = ApiCancellable &
  WithPeriodProps & {
    readonly offerType?: Nullable<EOfferType>;
  };

type MpUsersProps = ApiCancellable & WithPeriodProps;

type MpSocialPackagesProps = ApiCancellable & WithPeriodProps;

type MpBookingOrdersStatisticsProps = ApiCancellable &
  WithPeriodProps & {
    readonly partnerId?: Nullable<UUID>;
  };

type MpBookingOrdersFunnelsProps = ApiCancellable &
  WithPeriodProps & {
    readonly partnerId?: Nullable<UUID>;
  };

type MpBookingOrdersServicesProps = ApiCancellable &
  WithPeriodProps & {
    readonly partnerId?: Nullable<UUID>;
  };

type MpBookingOrdersItemsProps = ApiCancellable &
  WithPeriodProps & {
    readonly partnerId?: Nullable<UUID>;
  };

type SportUsersProps = ApiCancellable &
  WithPeriodProps & {
    readonly eventStartDate?: Nullable<string>;
    readonly eventEndDate?: Nullable<string>;
  };

type SportEventsProps = ApiCancellable & WithPeriodProps;

type ReportApi = {
  readonly mp: {
    readonly offers: (props: MpOffersProps) => Promise<AxiosResponse<string>>;
    readonly offersStatistic: (props: MpOffersStatisticProps) => Promise<AxiosResponse<string>>;
    readonly partners: (props: MpPartnersProps) => Promise<AxiosResponse<string>>;
    readonly users: (props: MpUsersProps) => Promise<AxiosResponse<string>>;
    readonly socialPackages: (props: MpSocialPackagesProps) => Promise<AxiosResponse<string>>;
    readonly bookingOrdersStatistic: (props: MpBookingOrdersStatisticsProps) => Promise<AxiosResponse<string>>;
    readonly bookingOrdersFunnels: (props: MpBookingOrdersFunnelsProps) => Promise<AxiosResponse<string>>;
    readonly bookingOrdersServices: (props: MpBookingOrdersServicesProps) => Promise<AxiosResponse<string>>;
    readonly bookingOrdersItems: (props: MpBookingOrdersItemsProps) => Promise<AxiosResponse<string>>;
  };
  readonly sport: {
    readonly users: (props: SportUsersProps) => Promise<AxiosResponse<string>>;
    readonly events: (props: SportEventsProps) => Promise<AxiosResponse<string>>;
    readonly eventsDetailed: (props: SportEventsProps) => Promise<AxiosResponse<string>>;
  };
};

/**
 * АПИ по работе с отчетами
 */
const user: ReportApi = {
  mp: {
    offers: ({ signal, startDate, endDate, offerType, partnerId }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }
      if (offerType) {
        if (typeof offerType === 'string') {
          params.append('offerType', offerType);
        } else {
          offerType.map(t => params.append('offerType', t));
        }
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      const url = partnerId ? `/reports/offers/partners/${partnerId}` : '/reports/offers/partners';

      return axios.get(`${getComServicesEndpoint() + url}`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    offersStatistic: ({ signal, startDate, endDate, offerType, partnerId, reportUserGroup }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (reportUserGroup) {
        params.append('reportUserGroup', reportUserGroup);
      }

      if (offerType) {
        if (typeof offerType === 'string') {
          params.append('offerType', offerType);
        } else {
          offerType.map(t => params.append('offerType', t));
        }
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      const url = partnerId ? `/reports/offer-statistic/partners/${partnerId}` : '/reports/offer-statistic/partners';

      return axios.get(`${getComServicesEndpoint() + url}`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },

    partners: ({ signal, startDate, endDate, offerType }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }
      if (offerType) {
        params.append('offerType', offerType);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      return axios.get(`${getComServicesEndpoint()}/reports/partners`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    users: ({ signal, startDate, endDate }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      return axios.get(`${getComServicesEndpoint()}/reports/users`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    socialPackages: ({ signal, startDate, endDate }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      return axios.get(`${getComServicesEndpoint()}/reports/social-packages`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    bookingOrdersStatistic: ({ signal, startDate, endDate, partnerId }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      const url = partnerId
        ? `/reports/order-statistics/booking/partners/${partnerId}`
        : '/reports/order-statistics/booking/partners';

      return axios.get(`${getComServicesEndpoint() + url}`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    bookingOrdersFunnels: ({ signal, startDate, endDate, partnerId }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      const url = partnerId
        ? `/reports/orders/booking-orders-funnel/partners/${partnerId}`
        : '/reports/orders/booking-orders-funnel';

      return axios.get(`${getComServicesEndpoint() + url}`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    bookingOrdersServices: ({ signal, startDate, endDate, partnerId }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      const url = partnerId
        ? `/reports/orders/booking-order-services/partners/${partnerId}`
        : '/reports/orders/booking-order-services';

      return axios.get(`${getComServicesEndpoint() + url}`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    bookingOrdersItems: ({ signal, startDate, endDate, partnerId }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      const url = partnerId
        ? `/reports/orders/booking-order-items/partners/${partnerId}`
        : '/reports/orders/booking-order-items';

      return axios.get(`${getComServicesEndpoint() + url}`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
  },
  sport: {
    users: ({ signal, startDate, endDate, eventStartDate, eventEndDate }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateFrom', startDate);
      }

      if (endDate) {
        params.append('dateTo', endDate);
      }

      if (eventStartDate) {
        params.append('dateEventFrom', eventStartDate);
      }

      if (eventEndDate) {
        params.append('dateEventTo', eventEndDate);
      }

      return axios.get(`${getSportBaseEndpoint()}/admin/reports/pulse-corp-users`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    events: ({ signal, startDate, endDate }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateEventFrom', startDate);
      }

      if (endDate) {
        params.append('dateEventTo', endDate);
      }

      return axios.get(`${getSportBaseEndpoint()}/admin/reports/events`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    eventsDetailed: ({ signal, startDate, endDate }) => {
      const params = new URLSearchParams();

      if (startDate) {
        params.append('dateEventFrom', startDate);
      }

      if (endDate) {
        params.append('dateEventTo', endDate);
      }

      return axios.get(`${getSportBaseEndpoint()}/admin/reports/detailed-events`, {
        params,
        responseType: 'arraybuffer',
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
  },
};

export default user;
