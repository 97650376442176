import { Banner, BannerPlace, EBannerPartition } from '@/domain';
import Splitter from '../../../components/common/splitter';
import BannerPreview from '../components/preview';
import BannerPreviewWrapper from '../components/preview/wrapper';
import {
  ColumnWrapper,
  Content,
  DesktopPreviewWrapper,
  MobilePreviewWrapper,
  PreviewWrapper,
  StepWrapper,
  Wrapper,
} from './controls';
import BannerViewStepGeneral from './steps/general';

interface BannerDetailsProps {
  readonly partition: EBannerPartition;
  readonly banner: Banner;
  readonly bannerPlaces: BannerPlace[];
}

const BannerDetails = (props: BannerDetailsProps) => {
  const { partition, banner, bannerPlaces } = props;

  return (
    <Wrapper>
      <StepWrapper>
        <ColumnWrapper>
          <Content>
            <BannerViewStepGeneral
              partition={partition}
              banner={banner}
              bannerPlaces={bannerPlaces}
            />
          </Content>
        </ColumnWrapper>
        <ColumnWrapper>
          <PreviewWrapper>
            <DesktopPreviewWrapper>
              <BannerPreviewWrapper label='Предпросмотр десктопного формата'>
                <BannerPreview src={banner.desktopFormatImage?.path ?? null} />
              </BannerPreviewWrapper>
            </DesktopPreviewWrapper>
            <Splitter
              variant='horizontal'
              size={3}
            />
            <MobilePreviewWrapper>
              <BannerPreviewWrapper label='Предпросмотр мобильного формата'>
                <BannerPreview src={banner.mobileFormatImage?.path ?? null} />
              </BannerPreviewWrapper>
            </MobilePreviewWrapper>
          </PreviewWrapper>
        </ColumnWrapper>
      </StepWrapper>
    </Wrapper>
  );
};

export default BannerDetails;
