import { Banner, BannerRequest, EBannerPartition, EBannerStatus, ENoticeStatus, Nullable, Target } from '@/domain';
import { ETagColors, MPTag } from '@/presentation/theme/ui-kit/tag';
import { ButtonLink } from '@components/common/buttons/link';
import { DefaultContentWrapper } from '@components/common/wrappers/content';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { Fade, Grid, Typography } from '@mui/material';
import { Location } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import useValidation from '../../../hooks/validation/useValidation';
import { PrivilegeRestoreIcon } from '../../../icons';
import { OnChangeObjectAttribute, PanelAction, PanelActions } from '../../../types';
import { createHTMLImageElementByFile } from '../../../utils/images';
import { ContentContainer } from '../../corpOffer/create/controls';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import StepperContainer from '../../general/stepper/container';
import { getBannersTableRoute } from '../entry';
import { BannerActionEditType, EBannerActionType } from '../types';
import {
  bannerDesktopImageRestriction,
  bannerDraftValidationRules,
  bannerImageSize,
  bannerMobileImageRestriction,
  bannerTargets,
  bannerValidationRules,
  getBannerActionTitle,
  getBannerEditActions,
  getBannerPlaceByPartition,
  getBannerTargetValidationRules,
} from '../utils';
import BannerEdit from './component';
import { ContainerWrapper, LoaderWrapper, TitleWrapper } from './controls';
import {
  bannerEditPublishSelector,
  bannerEditResumeSelector,
  bannerEditSaveSelector,
  bannerEditSelector,
} from './store/selectors';
import {
  bannerEditSave,
  bannerEditSaveAndPublish,
  bannerEditSaveAndResume,
  bannerEditSetAttribute,
  bannerEditSetTargetAttribute,
  bannerEditStartSession,
  bannerEditStateReset,
} from './store/slice';

interface BannerEditContainerProps {
  readonly partition: EBannerPartition;
  readonly source: Nullable<Banner>;
  readonly onRestore?: () => void;
}

const BannerEditContainer = (props: BannerEditContainerProps) => {
  const { partition, source, onRestore } = props;

  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const [validationDraft, setValidationDraft] = useState<boolean>(true);

  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const { bannerPlaces } = useSelector(nsiDataSelector);
  const { banner, modified } = useSelector(bannerEditSelector);
  const { isFetching: isSaving, isFetched: isSaved } = useSelector(bannerEditSaveSelector);
  const { isFetching: isPublishing, isFetched: isPublished } = useSelector(bannerEditPublishSelector);
  const { isFetching: isResuming, isFetched: isResumed } = useSelector(bannerEditResumeSelector);
  const isDraft = !source || source.status === EBannerStatus.Draft;
  const isPaused = source && source.status === EBannerStatus.Paused;

  const actions: PanelActions<BannerActionEditType> = getBannerEditActions(isDraft, !!isPaused);

  const { validationResult: validationResultDraft, validate: validateDraft } = useValidation<BannerRequest>({
    object: banner,
    validateOnChange,
    rules: bannerDraftValidationRules,
  });

  const { validationResult: validationResultToPublish, validate: validateToPublish } = useValidation<BannerRequest>({
    object: banner,
    validateOnChange,
    rules: bannerValidationRules,
  });

  const { validationResult: validationResultTarget, validate: validateTarget } = useValidation<Target>({
    object: banner.target,
    validateOnChange,
    rules: getBannerTargetValidationRules(partition),
  });

  const onChangeAttribute: OnChangeObjectAttribute<BannerRequest> = (name, value) => {
    dispatch(bannerEditSetAttribute({ name, value }));
  };

  const onChangeTargetAttribute = (name: keyof Target, value: any) => {
    dispatch(bannerEditSetTargetAttribute({ name, value }));
  };

  const onFilterDesktopImage = async (file: File) => {
    return createHTMLImageElementByFile(file).then(({ width, height }) => {
      if (width !== bannerImageSize.desktop.width || height !== bannerImageSize.desktop.height) {
        Notifier.getInstance().addNotice(ENoticeStatus.Error, bannerDesktopImageRestriction);
        return null;
      }
      return file;
    });
  };

  const onFilterMobileImage = async (file: File) => {
    return createHTMLImageElementByFile(file).then(({ width, height }) => {
      if (width !== bannerImageSize.mobile.width || height !== bannerImageSize.mobile.height) {
        Notifier.getInstance().addNotice(ENoticeStatus.Error, bannerMobileImageRestriction);
        return null;
      }
      return file;
    });
  };

  const onClose = useCallback(
    (fallback?: Nullable<string | Location>) => {
      gotoPrevIndependentLocation(fallback ?? getBannersTableRoute({ partition }));
    },
    [gotoPrevIndependentLocation, partition]
  );

  const onSaveAndPublish = () => {
    setValidationDraft(false);
    if (banner) {
      const isValidObject = validateToPublish() && validateTarget();
      if (!isValidObject) {
        setValidateOnChange(true);
        return;
      }
      dispatch(bannerEditSaveAndPublish({ request: banner, source }));
    }
  };

  const onSaveAndResume = () => {
    setValidationDraft(false);
    if (banner) {
      const isValidObject = validateToPublish() && validateTarget();
      if (!isValidObject) {
        setValidateOnChange(true);
        return;
      }
      dispatch(bannerEditSaveAndResume({ request: banner, source }));
    }
  };

  const onSaveDraft = () => {
    setValidationDraft(true);
    if (banner) {
      const isValidObject = validateDraft();
      if (!isValidObject) {
        setValidateOnChange(true);
        return;
      }
      dispatch(bannerEditSave({ request: banner, source }));
    }
  };

  const onPanelAction = (action: PanelAction<BannerActionEditType>) => {
    const { type } = action;
    switch (type) {
      case EBannerActionType.Save:
        onSaveDraft();
        break;
      case EBannerActionType.Activate:
        onSaveAndPublish();
        break;
      case EBannerActionType.Resume:
        onSaveAndResume();
        break;
    }
  };

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Баннер успешно сохранён`);
      onClose();
    }
  }, [isSaved, onClose]);

  useEffect(() => {
    if (isPublished) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Баннер успешно создан и опубликован`);
      onClose();
    }
  }, [isPublished, onClose]);

  useEffect(() => {
    if (isResumed) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Баннер успешно обновлен и опубликован`);
      onClose();
    }
  }, [isResumed, onClose]);

  useEffect(() => {
    dispatch(bannerEditStartSession({ place: getBannerPlaceByPartition(bannerPlaces, partition), source }));

    return () => {
      dispatch(bannerEditStateReset());
    };
  }, [dispatch, partition, source, bannerPlaces]);

  const isFetching = isSaving || isPublishing || isResuming;
  const validationResult = validationDraft ? validationResultDraft : validationResultToPublish;

  const actionsPanel = (
    <MasterActionsComponent<BannerActionEditType>
      show={!!actions.length}
      actions={actions}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {banner && <StepperContainer />}
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              actions={
                <Grid
                  container
                  spacing={4}
                  alignItems='center'
                >
                  <Grid item>
                    {source?.id && (
                      <MPTag
                        color={ETagColors.Success}
                        label={'В работе'}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {onRestore && modified && (
                      <ButtonLink onClick={onRestore}>
                        <PrivilegeRestoreIcon />
                        <Typography>Отменить все изменения</Typography>
                      </ButtonLink>
                    )}
                  </Grid>
                </Grid>
              }
              onClose={() => onClose()}
            >
              <TitleWrapper>
                <Typography variant='h2'>{getBannerActionTitle(source, partition)}</Typography>
              </TitleWrapper>
            </DefaultHeader>
            <BannerEdit
              partition={partition}
              banner={banner}
              validation={validationResult}
              validationTarget={validationResultTarget}
              places={bannerPlaces}
              targets={bannerTargets}
              desktopImageDescription={bannerDesktopImageRestriction}
              mobileImageDescription={bannerMobileImageRestriction}
              onFilterDesktopImage={onFilterDesktopImage}
              onFilterMobileImage={onFilterMobileImage}
              onChangeAttribute={onChangeAttribute}
              onChangeTargetAttribute={onChangeTargetAttribute}
            />
          </DefaultContentWrapper>
        </ContentContainer>
        {isFetching && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default BannerEditContainer;
