import { useReportDownloads } from '@features/report/downloads/useDownloads';
import { EReportType } from '@features/report/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reportDownloadsReportsSelector } from '../store/selectors';
import { BookingOrdersServicesContainer } from '../reports/BookingOrdersServices';

export const ReportDownloadsBookingOrdersServicesAdapter = () => {
  const handlers = useReportDownloads();

  const { booking_order_services: open } = useSelector(reportDownloadsReportsSelector);

  const onClose = useCallback(() => {
    handlers.onCloseDownloadDialog(EReportType.BookingOrdersServices);
  }, [handlers.onCloseDownloadDialog]);

  return (
    open && (
      <BookingOrdersServicesContainer
        onClose={onClose}
        partnerId={open.partnerId}
      />
    )
  );
};
