import { FormHelperText, Grid } from '@mui/material';
import { GridDirection } from '@mui/material/Grid/Grid';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Api from '../../../../../data/api';
import { AppFile } from '../../../../../domain/model';
import { ENoticeStatus } from '../../../../../domain/model/enums';
import { Nullable, UUID } from '../../../../../domain/model/types';
import Notifier from '../../../../../system/notifier';
import { ContentTypeImageIcon } from '../../../../icons';
import { convertBytesToUnitText } from '../../../../utils/files';
import ContentLoader from '../../loader';
import FileInfo from '../info';
import { FileUploaderAreaAdapter } from './areaAdapter';
import { Wrapper } from './controls';
import { FileUploaderAdapterType } from './types';

const cancelledLabel = 'cancelled' as const;

export interface FileUploaderProps<AdapterType extends FileUploaderAdapterType> {
  readonly adapter?: AdapterType;
  readonly text?: string | React.ReactNode;
  readonly disabled?: boolean;
  readonly description?: string | React.ReactNode;
  readonly fileTypes?: string[];
  readonly accept?: string[];
  readonly fileMaxSize?: number;
  readonly filesMaxCount?: number;
  readonly direction?: GridDirection;
  readonly icon?: JSX.Element;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly fileTypeErrorText?: string;
  readonly isLoading?: boolean;
  readonly externalSource?: Nullable<File>;
  readonly onFilter?: (files: File[]) => Promise<File[]>;
  readonly onSelect?: (files: File[]) => void;
  readonly onUpload?: (file: AppFile, binFiles: ActiveUpload[]) => void;
  readonly onError?: (file: File, msg: string) => void;
}

export interface ActiveUpload {
  readonly guid: UUID;
  readonly file: File;
  progress: number;
  cancel: any;
}

const FileUploader = <AdapterType extends FileUploaderAdapterType>(props: FileUploaderProps<AdapterType>) => {
  const {
    adapter: Adapter = FileUploaderAreaAdapter,
    text = 'Загрузите изображение',
    disabled = false,
    description,
    fileTypes = [],
    accept = [],
    filesMaxCount = 1,
    fileMaxSize,
    direction = 'column',
    icon = <ContentTypeImageIcon />,
    fileTypeErrorText = 'Недопустимый тип файла.',
    error,
    helperText,
    isLoading,
    externalSource,
    onFilter,
    onUpload,
    onSelect,
    onError,
  } = props;

  const [activeUploads, setActiveUploads] = useState<ActiveUpload[]>([]);
  const [toActiveUploadRemove, setToActiveUploadRemove] = useState<Nullable<UUID>>(null);
  const [toActiveUploadUpdateProgress, setToActiveUploadUpdateProgress] =
    useState<Nullable<{ guid: UUID; progress: number }>>(null);
  const [toActiveUploadCancel, setToActiveUploadCancel] = useState<Nullable<{ guid: UUID; process: any }>>(null);
  const [toAdd, setToAdd] = useState<Nullable<AppFile>>(null);

  const updateActiveUploadProgress = (guid: UUID, progress: number) => {
    setActiveUploads(prevUploads =>
      prevUploads.map(upload => (upload.guid === guid ? { ...upload, progress } : upload))
    );
  };

  const updateActiveUploadProcess = (guid: UUID, process: any) => {
    setActiveUploads(prevUploads =>
      prevUploads.map(upload => (upload.guid === guid ? { ...upload, cancel: process } : upload))
    );
  };

  const removeActiveUpload = (guid: UUID) => {
    setActiveUploads(prevUploads => prevUploads.filter(upload => upload.guid !== guid));
  };

  const cancelActiveUpload = (activeUpload: ActiveUpload) => {
    activeUpload.cancel?.({ label: cancelledLabel, guid: activeUpload.guid });
    setToActiveUploadRemove(activeUpload.guid);
  };

  const upload = (files: File[]) => {
    if (files.length === 0) return;

    const filesToUpload = files.slice(0, 1).filter(file => {
      const fileType = file.type;

      if (fileTypes.length > 0 && !fileTypes.includes(fileType)) {
        Notifier.getInstance().addNotice(
          ENoticeStatus.Error,
          `Недопустимый тип файла. Разрешены к загрузке ${fileTypes.join(', ')}.`
        );
        if (onError) onError(file, fileTypeErrorText);
        return false;
      }

      if (fileMaxSize && file.size > fileMaxSize) {
        Notifier.getInstance().addNotice(
          ENoticeStatus.Error,
          `Максимальный размер файла ${convertBytesToUnitText(fileMaxSize)}`
        );
        if (onError) onError(file, `Максимальный размер файла ${convertBytesToUnitText(fileMaxSize)}`);
        return false;
      }

      return true;
    });

    const uploadOrSelect = (uploadOrSelectFiles: File[]) => {
      const uploads: ActiveUpload[] = uploadOrSelectFiles.map(file => ({
        guid: uuidv4(),
        file,
        progress: 0,
        cancel: null,
      }));

      if (onUpload) {
        setActiveUploads(current => [...current, ...uploads]);
        Promise.allSettled(
          uploads.map(uploadItem => {
            const config: AxiosRequestConfig = {
              onUploadProgress: event => {
                if (event.loaded) {
                  const progress = Math.round((event.loaded * 100) / (event.total ?? 0));
                  setToActiveUploadUpdateProgress({ guid: uploadItem.guid, progress });
                }
              },
              cancelToken: new axios.CancelToken(cancel => {
                setToActiveUploadCancel({ guid: uploadItem.guid, process: cancel });
              }),
              // @ts-ignore
              guid: uploadItem.guid,
            };
            return Api.files.upload(uploadItem.file, config);
          })
        ).then(results => {
          results.forEach((result, index) => {
            if (result.status === 'rejected') {
              if (result.reason?.message?.guid) {
                setToActiveUploadRemove(result.reason.message.guid);
              } else {
                setToActiveUploadRemove(uploads[index].guid);
              }
              if (result.reason?.message?.label !== cancelledLabel)
                Notifier.getInstance().addNotice(
                  ENoticeStatus.Error,
                  `Произошла ошибка при загрузке файла: ${uploads[index].file.name}`
                );
            } else {
              setToActiveUploadRemove(uploads[index].guid);
              setToAdd(result.value.data);
            }
          });
        });
      } else if (onSelect) {
        onSelect(uploadOrSelectFiles);
      }
    };

    new Promise<File[]>(resolve => {
      if (onFilter) {
        resolve(onFilter(filesToUpload));
      }
      resolve(filesToUpload);
    }).then(outFiles => uploadOrSelect(outFiles));
  };

  useEffect(() => {
    if (toActiveUploadCancel) {
      updateActiveUploadProcess(toActiveUploadCancel.guid, toActiveUploadCancel.process);
      setToActiveUploadCancel(null);
    }
  }, [toActiveUploadCancel]);

  useEffect(() => {
    if (toActiveUploadRemove) {
      removeActiveUpload(toActiveUploadRemove);
      setToActiveUploadRemove(null);
    }
  }, [toActiveUploadRemove]);

  useEffect(() => {
    if (toActiveUploadUpdateProgress) {
      updateActiveUploadProgress(toActiveUploadUpdateProgress.guid, toActiveUploadUpdateProgress.progress);
      setToActiveUploadUpdateProgress(null);
    }
  }, [toActiveUploadUpdateProgress]);

  useEffect(() => {
    if (toAdd) {
      if (onUpload) {
        onUpload(toAdd, activeUploads);
      }
      setToAdd(null);
    }
  }, [toAdd, activeUploads]);

  useEffect(() => {
    if (externalSource) {
      upload([externalSource]);
    }
  }, [externalSource]);

  return (
    <Wrapper
      container
      spacing={2}
      direction={direction}
    >
      {isLoading && <ContentLoader />}
      {activeUploads.map((activeUpload, index) => (
        <Grid
          item
          key={index}
        >
          <FileInfo
            icon={icon}
            originalName={activeUpload.file.name}
            loadingProgress={activeUpload.progress}
            onRemove={() => cancelActiveUpload(activeUpload)}
          />
          {error && <FormHelperText error>{helperText}</FormHelperText>}
        </Grid>
      ))}
      {activeUploads.length < filesMaxCount && (
        <Grid item>
          <Adapter
            disabled={disabled}
            text={text}
            description={description}
            accept={accept}
            error={error}
            onUpload={upload}
          />
          {error && <FormHelperText error>{helperText}</FormHelperText>}
        </Grid>
      )}
    </Wrapper>
  );
};

export default FileUploader;
